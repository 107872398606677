import { useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { getProjects } from '../API/request/getProjects';

const StateContext = createContext();

function StateProvider({ children }) {
  const [projects, setProjects] = useState();
  const [category, setCategory] = useState('');
  const [categoryScroll, setCategoryScroll] = useState(0);
  const [portfolioScroll, setPortfolioScroll] = useState(0);
  const [sliderCenter, setSliderCenter] = useState(0);
  const [isSnow, setIsSnow] = useState(false);

  useEffect(() => {
    getProjects().then((data) => setProjects(data?.results));
  }, []);

  useEffect(() => {
    setSliderCenter(projects?.length / 2);
  }, [projects]);

  return (
    <StateContext.Provider
      value={{
        category,
        setCategory,
        sliderCenter,
        setSliderCenter,
        categoryScroll,
        setCategoryScroll,
        portfolioScroll,
        setPortfolioScroll,
        isSnow,
        setIsSnow,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export { StateProvider, StateContext };
