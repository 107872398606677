import { BASE_URL } from '../BaseURI';

export const getProject = async (id) => {
  const response = await fetch(BASE_URL + `portfolio/project/${id}`, {
    method: 'GET',
  });

  const data = await response.json();
  return data;
};
