import { BASE_URL } from '../BaseURI';

export const sendCalculate = async (userData) => {
  const response = await fetch(BASE_URL + 'notifications/email/calculate/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      project_description: userData.text,
      service_id: 1,
    }),
  });

  const data = await response.json();
  return data;
};
