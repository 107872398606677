import React, { useContext, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { ReactComponent as MenuArrow } from '../../../assets/image/mobile-menu-item.svg';
import styles from './NavMenu.module.scss';
import Toggle from '../Toggle/Toggle';
import { StateContext } from '../../../context/StateContext';

const NavMenuMobile = ({ isShowMenu, setShowMenu }) => {
  const [links, setLinks] = useState([
    { title: 'О компании', link: '/about' },
    { title: 'Портфолио', link: '/portfolio' },
    { title: 'Услуги', link: '/services' },
    { title: 'Контакты', link: '/contacts' },
  ]);
  const { setPortfolioScroll } = useContext(StateContext);

  return (
    <ul
      onClick={() => setShowMenu(false)}
      className={styles.mobileMenuWrap}
      style={
        !isShowMenu
          ? {
              zIndex: -100,
              backgroundColor: 'none',
              opacity: '0',
              visibility: 'hidden',
            }
          : { zIndex: 10, backgroundColor: 'rgba(0, 0, 0, 0.5)' }
      }
    >
      {links.map((item, index) => (
        <li
          onClick={(e) => e.stopPropagation()}
          key={index}
          style={
            !isShowMenu
              ? { transform: 'translateX(200px)' }
              : { transform: 'translateX(0px)' }
          }
        >
          <NavLink onClick={() => setPortfolioScroll(0)} to={item.link}>
            {item.title}
          </NavLink>
        </li>
      ))}
      <li
        onClick={(e) => e.stopPropagation()}
        className={styles.toggle}
        style={
          !isShowMenu
            ? { transform: 'translateX(200px)' }
            : { transform: 'translateX(0px)' }
        }
      >
        <Toggle />
      </li>
    </ul>
  );
};

export default NavMenuMobile;
