import './App.css';
import { Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services/Services';
import ProjectPage from './components/pages/ProjectPage/ProjectPage';
import Astafiev from './components/pages/Astafiev/Astafiev';
import Contacts from './components/pages/Contacts/Contacts';
import About from './components/pages/About/About';
import Main from './components/pages/Main/Main';
import Portfolio from './components/pages/Portfolio/Portfolio';
import Service from './components/pages/Service/Service';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='/about' element={<About />} />
      <Route path='/portfolio' element={<Portfolio />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/services' element={<Services />} />
      <Route path='/service/:id' element={<Service />} />
      <Route path='/project/:id' element={<ProjectPage />} />

      <Route path='/astafiev/:id' element={<Astafiev />} />
    </Routes>
  );
}

export default App;
