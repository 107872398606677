import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import Iteo from '../../../assets/image/iteo-about.png';
import Iteo2 from '../../../assets/image/iteo-about2.png';
import Iteo3 from '../../../assets/image/iteo-about3.jpg';
import Arrow from '../../../assets/image/arrow.svg';

import styles from './About.module.scss';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../context/theme-context';
import { StateContext } from '../../../context/StateContext';
import useImagePreloader from '../../../hooks/useImagePreloader';
import Loader from '../../Loader/Loader';
import { getProjects } from '../../../API/request/getProjects';
import { getServiceCategories } from '../../../API/request/getServiceCategories';
import { Helmet } from 'react-helmet';

const About = () => {
  const { imagesPreloaded } = useImagePreloader([Iteo, Iteo2, Iteo3]);

  const { category, setCategory, categoryScroll, setCategoryScroll } =
    useContext(StateContext);

  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState(category);
  const { theme, toggle, light } = useContext(ThemeContext);

  const navigate = useNavigate();
  const scrollRef = useRef();

  useEffect(() => {
    getProjects().then((data) => setProjects(data?.results));
    getServiceCategories().then((data) => setCategories(data?.results));
  }, []);

  useEffect(() => {
    if (imagesPreloaded && categoryScroll) {
      scrollRef.current.scrollIntoView();
      setCategoryScroll(0);
    }
  }, [scrollRef, imagesPreloaded, categoryScroll]);

  return (
    <>
      <Helmet>
        <title>Итео - О компании</title>
        <meta
          name='description'
          content='Мы очень творческая, дружная команда, совмещающая практичность и инновации с крышесносными идеями. Наша работа заключается в том, чтобы найти наиболее эффективный, удобный и красивый способ решения задачи.'
        />
      </Helmet>
      <PageTemplate>
        <Container width={70}>
          <section className={styles.about}>
            <h2
              className={classNames(styles.title, {
                [styles.titleWhite]: !light,
              })}
            >
              Как мы работаем
            </h2>
            {imagesPreloaded ? (
              <>
                <div className={styles.content}>
                  <img className={styles.firstIteoImage} src={Iteo} />
                  <div className={styles.firstAbout}>
                    <span className={styles.firstCount}>1</span>
                    <p>
                      Интерактивная мастерская ITEO основана в 2014 году.
                      Основные направления нашей работы — разработка
                      интерактивных инсталляций и презентаций для музеев и
                      выставочных пространств.
                    </p>
                    <span className={styles.secondCount}>2</span>
                    <img className={styles.secondIteoImage} src={Iteo2} />
                  </div>
                  <img className={styles.thirdIteoImage} src={Iteo3} />
                  <div className={styles.secondAbout}>
                    <p>
                      Мы очень творческая, дружная команда, совмещающая
                      практичность и инновации с крышесносными идеями. Наша
                      работа заключается в том, чтобы найти наиболее
                      эффективный, удобный и красивый способ решения задачи.
                    </p>
                    <span className={styles.thirdCount}>3</span>
                    <div className={styles.arrow}>
                      <img src={Arrow} />
                      <p>наша команда</p>
                    </div>
                  </div>
                </div>
                <div className={styles.background}></div>
              </>
            ) : (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}
          </section>
        </Container>
        <section className={styles.projects}>
          <p className={styles.tagline}>
            «Мы любим и умеем точно решать сложные бизнес-задачи разных компаний
            и делать лучше жизнь людей — с помощью интерактивных решений. Мы
            делаем доступным новый уровень восприятия окружающего мира, помогая
            Вашему бизнесу соответствовать вызовам современности!»
          </p>         
          <p ref={scrollRef} className={styles.title}>
            ГОТОВЫЕ ПРОЕКТЫ
          </p>
          <div className={styles.categories}>
            <button
              onClick={() => {
                setFilter('');
                setCategory('');
              }}
              className={classNames({ [styles.active]: !filter })}
            >
              всё
            </button>
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => {
                  setFilter(category.id);
                  setCategory(category.id);
                }}
                className={classNames({
                  [styles.active]: filter == category.id,
                })}
              >
                {category.category_name}
              </button>
            ))}
            {/* <button
            onClick={() => {
              setFilter('vr');
              setCategory('vr');
            }}
            className={classNames({ [styles.active]: filter == 'vr' })}
          >
            vr/ar
          </button>
          <button
            onClick={() => {
              setFilter('museums');
              setCategory('museums');
            }}
            className={classNames({ [styles.active]: filter == 'museums' })}
          >
            музеи / выставки
          </button>
          <button
            onClick={() => {
              setFilter('sites');
              setCategory('sites');
            }}
            className={classNames({ [styles.active]: filter == 'sites' })}
          >
            сайты
          </button>
          <button
            onClick={() => {
              setFilter('apps');
              setCategory('apps');
            }}
            className={classNames({ [styles.active]: filter == 'apps' })}
          >
            приложения
          </button> */}
          </div>
          <div className={styles.projects}>
            {projects.map((project, id) =>
              !filter ? (
                <div
                  key={id}
                  onClick={() => {
                    navigate('/project/' + project.id);
                    setCategoryScroll(1);
                  }}
                  className={styles.project}
                >
                  <img src={project.project_cover_img} />
                  <p>{project.title}</p>
                </div>
              ) : project.category.find((item) => item == filter) ? (
                <div
                  key={id}
                  onClick={() => {
                    navigate('/project/' + project.id);
                    setCategoryScroll(1);
                  }}
                  className={styles.project}
                >
                  <img src={project.project_cover_img} />
                  <p>{project.title}</p>
                </div>
              ) : null
            )}
          </div>
        </section>
      </PageTemplate>
    </>
  );
};

export default About;
