import styles from './Astafiev.module.scss';

export const Canvas = ({children, id}) => {

    let canvas
    switch(id){
        case '1':
            canvas = styles.canvas1
            break;
        case '2':
            canvas = styles.canvas2
            break;
        case '3':
            canvas = styles.canvas3
            break;
        case '4':
            canvas = styles.canvas4
            break;
    }

    return <div className={canvas}>
        {children}
    </div>

}