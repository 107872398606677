import React, { useEffect, useState } from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';

import { useParams, Link } from 'react-router-dom';

import styles from './ProjectPage.module.scss';
import ProjectSlider from '../../elements/ProjectSlider/ProjectSlider';
import FullScreenImage from '../../elements/FullScreenImage/FullScreenImage';
import { getProject } from '../../../API/request/getProject';
import { Helmet } from 'react-helmet';
import closeIcon from '../../../assets/image/close.svg';

const ProjectPage = () => {
  const [tour3D, setTour3D] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowNext, setIsShowNext] = useState(false);
  const [isShowprev, setIsShowprev] = useState(false);
  const [currentImg, setCurrentImg] = useState();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (isShowModal) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100%';
    } else {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').style.height = 'auto';
    }
  }, [isShowModal]);

  const [currentProject, setCurrentProject] = useState();
  const openModal = (photo) => {
    // if (window.innerWidth < 768) return;
    setShowModal(true);
    const image = currentProject.project_images.filter(
      (item) => item.image == photo
    )[0].image;
    setCurrentImg(image);
  };

  useEffect(() => {
    if (!currentImg) return;
    const imgIndex = currentProject.project_images.findIndex(
      (item) => item.image == currentImg
    );
    if (imgIndex === 0 && currentProject.project_images.length > 1) {
      setIsShowprev(false);
      setIsShowNext(true);
    } else if (currentProject.project_images.length === 1) {
      setIsShowprev(false);
      setIsShowNext(false);
    } else if (imgIndex === currentProject.project_images.length - 1) {
      setIsShowprev(true);
      setIsShowNext(false);
    } else if (
      imgIndex === currentProject.project_images.length - 1 &&
      imgIndex === 0
    ) {
      setIsShowprev(false);
      setIsShowNext(false);
    } else {
      setIsShowprev(true);
      setIsShowNext(true);
    }
  }, [currentImg]);

  const next = () => {
    const imgIndex = currentProject.project_images.findIndex(
      (item) => item.image == currentImg
    );
    if (currentProject.project_images[imgIndex + 1])
      setCurrentImg(currentProject.project_images[imgIndex + 1].image);
  };

  const prev = () => {
    const imgIndex = currentProject.project_images.findIndex(
      (item) => item.image == currentImg
    );
    if (currentProject.project_images[imgIndex - 1])
      setCurrentImg(currentProject.project_images[imgIndex - 1].image);
  };

  useEffect(() => {
    getProject(id).then((data) => setCurrentProject(data));
  }, [id]);

  return (
    <>
      <Helmet>
        <title>
          Итео - {currentProject?.title ? currentProject?.title : 'Проект'}
        </title>
      </Helmet>
      <PageTemplate>
        <Container width={90}>
          {currentProject && (
            <div className={styles.projectItem}>
              {isShowModal && (
                <FullScreenImage
                  currentImg={currentImg}
                  next={next}
                  prev={prev}
                  close={() => setShowModal(false)}
                  isNext={isShowNext}
                  isPrev={isShowprev}
                  isHave360={currentProject.project_photo_360.length > 0}
                  photo360={currentProject.project_photo_360[0]?.image_360}
                />
              )}
              <div className={styles.content}>
                <div className={styles.slider}>
                  <ProjectSlider
                    photos={currentProject.project_images}
                    openModal={openModal}
                  />
                </div>
                <div className={styles.text}>
                  <h2>{currentProject.title}</h2>
                  <p
                    className={styles.textParagraph}
                    dangerouslySetInnerHTML={{
                      __html: currentProject.text,
                    }}
                  ></p>
                  {id == 3 && (
                    <button
                      onClick={() => setTour3D(true)}
                      className={styles.tourButton}
                    >
                      3D-тур
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 30,
                }}
              >
                <Link to={-1}>
                  <span
                    style={{
                      backgroundColor: '#05B7E0',
                      padding: '5px 10px',
                    }}
                  >
                    Назад
                  </span>
                </Link>
              </div>
              {tour3D && (
                <>
                  <iframe
                    className={styles.tour}
                    src='https://iteo.pro/360/sovmen/'
                  ></iframe>
                  <img
                    src={closeIcon}
                    alt=''
                    className={styles.close}
                    onClick={() => setTour3D(false)}
                  />
                </>
              )}
            </div>
          )}
        </Container>
      </PageTemplate>
    </>
  );
};

export default ProjectPage;
