import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Toggle.module.scss';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';
import { StateContext } from '../../../context/StateContext';
import { useLocation } from 'react-router-dom';

const Toggle = ({ isSliderPage }) => {
  const { theme, toggle, light } = useContext(ThemeContext);
  const { sliderCenter, setSliderCenter } = useContext(StateContext);
  const [isOn, setIsOn] = useState(light);
  const location = useLocation();

  useEffect(() => {
    setIsOn(light);
  }, [light]);

  return (
    <div
      className={styles.container}
      data-darkmode={isOn}
      onClick={() => {
        if (location.pathname == '/') {
          setSliderCenter(
            document.querySelector('div[data-x="0"]').id > 5 &&
              document.querySelector('div[data-x="0"]').id < 15
              ? document.querySelector('div[data-x="0"]').id - 1
              : document.querySelector('div[data-x="0"]').id
          );
        }
        toggle();
      }}
      style={{ justifyContent: isOn ? 'flex-end' : 'flex-start' }}
    >
      <motion.div className={styles.handle}>
        <AnimatePresence mode='wait' initial={false}></AnimatePresence>
      </motion.div>
    </div>
  );
};

export default Toggle;
