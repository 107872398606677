import styles from './Astafiev.module.scss';
import { useParams } from 'react-router-dom';


import { Helmet } from 'react-helmet';
import { AudioPlayer } from './Player/AudioPlayer';
import { BookCover } from './BookCover';
import { Description } from './Description';
import { AudioNote } from './AudioNote';
import { Header } from './Header';
import { Ticket } from './Ticket';
import { Canvas } from './Canvas';

const Astafiev = ({}) => {
  const isMobile = window.innerWidth < 550
  const { id } = useParams();

  const content = {
    1: 'PIU',
    2: 'CR',
    3: 'VO',
    4: 'KSRG',
  }
  
  const mobileLayout = (
    <div className={styles.mobileLayout}>
      <div className={styles.cardMobile}>
            <div className={styles.gridContainer}>
              <div>
                <Header content={content[id]} />
              </div>
              <div className={styles.bookGridContainer}>
                <BookCover content={content[id]} />
                <Description content={content[id]} />
              </div>
              <div className={styles.audioContainer}>
                <AudioPlayer content={content[id]}/>
              </div>
              <div>
                <AudioNote />
              </div>
            </div>
          </div>
          <Ticket />
    </div>
  )

  const desctopLayout = (
    <>
      <Ticket />
      <div className={styles.cardDesktop}>
        <div className={styles.gridContainer}>
          <div>
            <BookCover content={content[id]}/>
          </div>
          
          <div className={styles.gridContentRows}>
            <Header content={content[id]} />
            <Description content={content[id]} />
            <AudioNote />
            <AudioPlayer content={content[id]}/>
          </div>


        </div>
      </div>
    </>
  )



  
  return (
    <>
      <Helmet>
        <title>
          Итео - Астафьев
        </title>
      </Helmet>
      <Canvas id={id}>
          {isMobile ? mobileLayout : desctopLayout}        
      </Canvas>
    </>
  );
};

export default Astafiev;
