import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../../context/theme-context';

import styles from './Slider.module.scss';
import './slider.scss';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { StateContext } from '../../../context/StateContext';
import useImagePreloader from '../../../hooks/useImagePreloader';
import Loader from '../../Loader/Loader';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getProjects } from '../../../API/request/getProjects';

const projectsPhoto = (projects) => {
  const res = [];
  for (let i = 0; i < projects.length; i++) {
    res.push(projects[i].main_page_slider_img);
  }
  return res;
};

const Slider = () => {
  const [projects, setProjects] = useState([]);

  const { theme, toggle, light } = useContext(ThemeContext);
  const { sliderCenter, setSliderCenter } = useContext(StateContext);
  const [tmpProjects, setTmpProjects] = useState(projects);
  const width = useWindowWidth();

  useEffect(() => {
    getProjects().then((data) => setProjects(data?.results));
  }, []);

  useEffect(() => {
    const cardsContainer = document.querySelector('.cardCarousel');
    const cards = document.querySelectorAll('.card');

    class DraggingEvent {
      constructor(target = undefined) {
        this.target = target;
      }

      event(callback) {
        let handler;

        this.target.addEventListener('mousedown', (e) => {
          e.preventDefault();

          handler = callback(e);

          window.addEventListener('mousemove', handler);

          document.addEventListener('mouseleave', clearDraggingEvent);

          window.addEventListener('mouseup', clearDraggingEvent);

          function clearDraggingEvent() {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('mouseup', clearDraggingEvent);

            document.removeEventListener('mouseleave', clearDraggingEvent);

            handler(null);
          }
        });

        this.target.addEventListener('touchstart', (e) => {
          handler = callback(e);

          window.addEventListener('touchmove', handler);

          window.addEventListener('touchend', clearDraggingEvent);

          document.body.addEventListener('mouseleave', clearDraggingEvent);

          function clearDraggingEvent() {
            window.removeEventListener('touchmove', handler);
            window.removeEventListener('touchend', clearDraggingEvent);

            handler(null);
          }
        });
      }

      // Get the distance that the user has dragged
      getDistance(callback) {
        function distanceInit(e1) {
          let startingX, startingY;

          if ('touches' in e1) {
            startingX = e1.touches[0].clientX;
            startingY = e1.touches[0].clientY;
          } else {
            startingX = e1.clientX;
            startingY = e1.clientY;
          }

          return function (e2) {
            if (e2 === null) {
              return callback(null);
            } else {
              if ('touches' in e2) {
                return callback({
                  x: e2.touches[0].clientX - startingX,
                  y: e2.touches[0].clientY - startingY,
                });
              } else {
                return callback({
                  x: e2.clientX - startingX,
                  y: e2.clientY - startingY,
                });
              }
            }
          };
        }

        this.event(distanceInit);
      }
    }

    class CardCarousel extends DraggingEvent {
      constructor(container, controller = undefined) {
        super(container);

        // DOM elements
        this.container = container;
        this.controllerElement = controller;
        this.cards = cards;

        // Carousel data
        this.centerIndex = (this.cards.length - 1) / 2;
        this.cardWidth =
          (this.cards[0].offsetWidth / this.container.offsetWidth) * 100;
        this.xScale = {};
        // Resizing
        // window.addEventListener('resize', this.updateCardWidth.bind(this));
        // window.addEventListener(
        //   'DOMContentLoaded',
        //   this.updateCardWidth.bind(this)
        // );
        window.addEventListener('load', this.updateCardWidth.bind(this));

        if (this.controllerElement) {
          this.controllerElement.addEventListener(
            'keydown',
            this.controller.bind(this)
          );
        }

        // Initializers
        this.build();

        // Bind dragging event
        super.getDistance(this.moveCards.bind(this));
      }

      updateCardWidth() {
        this.cardWidth =
          (this.cards[0].offsetWidth / this.container.offsetWidth) * 100;
        this.build();
      }

      build(fix = 0) {
        for (let i = 0; i < this.cards.length; i++) {
          const x = i - this.centerIndex;
          const scale = this.calcScale(x);
          const scale2 = this.calcScale2(x);
          const zIndex = -Math.abs(i - this.centerIndex);

          const leftPos = this.calcPos(x, scale2);

          this.xScale[x] = this.cards[i];

          this.updateCards(this.cards[i], {
            x: x,
            scale: scale,
            leftPos: leftPos,
            zIndex: zIndex,
          });
        }
      }

      calcPos(x, scale) {
        let formula;
        if (x < 0) {
          formula = (scale * 100 - this.cardWidth) / 2;
          return formula;
        } else if (x > 0) {
          formula = 100 - (scale * 100 + this.cardWidth) / 2;
          return formula;
        } else {
          formula = 100 - (scale * 100 + this.cardWidth) / 2;

          return formula;
        }
      }

      updateCards(card, data) {
        if (data.x || data.x == 0) {
          card.setAttribute('data-x', data.x);
        }
        if (data.scale || data.scale == 0) {
          card.style.transform = `perspective(300px) scale(${data.scale.scale}) rotateY(${data.scale.rotate}deg)`;

          if (data.scale == 0) {
            card.style.opacity = data.scale;
          } else {
            card.style.opacity = 1;
          }
        }
        if (data.leftPos) {
          card.style.left = `${data.leftPos}%`;
        }

        if (data.zIndex || data.zIndex == 0) {
          if (data.zIndex == 0) {
            card.classList.add('highlight');
          } else {
            card.classList.remove('highlight');
          }

          card.style.zIndex = data.zIndex;
        }
        if (card.getAttribute('data-x') == 0) {
          card.style.boxShadow = '0px 7px 8px 0px rgb(0, 187, 230)';
        } else {
          card.style.boxShadow = '0px 0px 0px 0px rgb(0, 216, 230)';
        }
      }

      calcScale2(x) {
        let formula;

        if (x <= 0) {
          if (x == -2) {
            formula = 1 - (-1 / 3.7) * x;
          } else {
            formula = 1 - (-1 / 3.7) * x;
          }

          return formula;
        } else if (x > 0) {
          if (x == 2) {
            formula = 1 - (1 / 3.7) * x;
          } else {
            formula = 1 - (1 / 3.7) * x;
          }
          return formula;
        }
      }

      calcScale(x) {
        const formula = 1 - (1 / 25) * Math.pow(x, 2);
        const rotate = -7 * x;

        if (formula <= 0) {
          return 0;
        } else {
          return { scale: formula, rotate: rotate };
        }
      }

      checkOrdering(card, x, xDist) {
        const original = parseInt(card.dataset.x);
        const rounded = Math.round(xDist);
        let newX = x;

        if (x !== x + rounded) {
          if (x + rounded > original) {
            if (x + rounded > this.centerIndex) {
              newX =
                x +
                rounded -
                1 -
                this.centerIndex -
                rounded +
                -this.centerIndex;
            }
          } else if (x + rounded < original) {
            if (x + rounded < -this.centerIndex) {
              newX =
                x + rounded + 1 + this.centerIndex - rounded + this.centerIndex;
            }
          }

          this.xScale[newX + rounded] = card;
        }

        const temp = -Math.abs(newX + rounded);

        this.updateCards(card, { zIndex: temp });

        return newX;
      }

      moveCards(data) {
        let xDist;
        if (data != null) {
          for (let i = 0; i < this.cards.length; i++) {
            cards[i].classList.add('Block');
          }
          this.container.classList.remove('smooth-return');
          if (width >= 1000) {
            xDist = data.x / 350;
          } else {
            xDist = data.x / 200;
          }
        } else {
          for (let i = 0; i < this.cards.length; i++) {
            cards[i].classList.remove('Block');
          }
          this.container.classList.add('smooth-return');
          xDist = 0;

          for (let x in this.xScale) {
            this.updateCards(this.xScale[x], {
              x: x,
              zIndex: Math.abs(Math.abs(x) - this.centerIndex),
            });
          }
        }

        for (let i = 0; i < this.cards.length; i++) {
          const x = this.checkOrdering(
              this.cards[i],
              parseInt(this.cards[i].dataset.x),
              xDist
            ),
            scale = this.calcScale(x + xDist),
            scale2 = this.calcScale2(x + xDist),
            leftPos = this.calcPos(x + xDist, scale2);

          this.updateCards(this.cards[i], {
            scale: scale,
            leftPos: leftPos,
          });
        }
      }
    }
    if (tmpProjects.length > 0) {
      const carousel = new CardCarousel(cardsContainer);
    }
  });

  useEffect(() => {
    
    if (sliderCenter > 0) {
      const centerProjects = [...projects];

      for (let i = 0; i < Math.abs(sliderCenter - projects.length / 2); i++)
        if (sliderCenter > projects.length / 2) {
          centerProjects.push(centerProjects.shift());
        } else {
          centerProjects.unshift(centerProjects.pop());
        }

      if (centerProjects.length % 2 == 0) {
        // setTmpProjects([...tmpProjects, centerProjects[centerProjects.length / 2]]);
        setTmpProjects([
          ...centerProjects.filter((project) => project.sort_index != 5),
        ]);
      }
    }
  }, [projects.length, sliderCenter]);

  const { imagesPreloaded } = useImagePreloader(projectsPhoto(tmpProjects));
  return (
    <div className={styles.container}>
      <div className={'cardCarousel'}>
        <div
          className={classNames(styles.cardCarouselInnerLeft, {
            [styles.light]: light,
          })}
        ></div>
        {tmpProjects.map((item) => (
          <div
            onClick={() =>
              setSliderCenter(
                document.querySelector('div[data-x="0"]').id > 5 &&
                  document.querySelector('div[data-x="0"]').id < 15
                  ? document.querySelector('div[data-x="0"]').id - 1
                  : document.querySelector('div[data-x="0"]').id
              )
            }
            key={item.sort_index}
            className={'card'}
            id={item.sort_index}
          >
            <Link
              to={'/project/' + item.id}
              style={{ height: '100%', width: '100%' }}
            >
              {imagesPreloaded ? (
                <div
                  className={styles.imageContainer}
                  style={{
                    backgroundImage: `url('${item.main_page_slider_img}')`,
                  }}
                >
                  <div className={styles.title}>
                    <span className={styles.titleText}>{item.title}</span>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </Link>
          </div>
        ))}
        <div
          className={classNames(styles.cardCarouselInnerRight, {
            [styles.light]: light,
          })}
        ></div>
      </div>
    </div>
  );
};

export default Slider;
