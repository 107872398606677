import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Arenda from '../SVGs/Arenda';
import ArendaA from '../SVGs/ArendaA';
import Sites from '../SVGs/Sites';
import SitesA from '../SVGs/SitesA';
import Museums from '../SVGs/Museums';
import MuseumsA from '../SVGs/MuseumsA';
import MobileApps from '../SVGs/MobileApps';
import MobileAppsA from '../SVGs/MobileAppsA';
import VR from '../SVGs/VR';
import VRA from '../SVGs/VRA';

import styles from './Skills.module.scss';
import classNames from 'classnames';
import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const Skills = () => {
  const [isUpdateSites, setIsUpdateSites] = useState(false);
  const [isUpdateMuseums, setIsUpdateMuseums] = useState(false);
  const [isUpdateArenda, setIsUpdateArenda] = useState(false);
  const [isUpdateVR, setIsUpdateVR] = useState(false);
  const [isUpdateMobileApps, setIsUpdateMobileApps] = useState(false);
  const { theme, toggle, light } = useContext(ThemeContext);
  const location = useLocation();
  
  return (
    <div className={styles.container}>
      <Link to={'/service/5'} state={{ prevPath: location.pathname }}>
        <div className={styles.item}>
          {isUpdateArenda ? (
            <ArendaA setIsUpdateArenda={setIsUpdateArenda} />
          ) : (
            <Arenda setIsUpdateArenda={setIsUpdateArenda} />
          )}
          <span
            className={classNames(styles.description, {
              [styles.lightDescription]: light,
            })}
          >
            аренда оборудования
          </span>
        </div>
      </Link>
      <Link to={'/service/2'} state={{ prevPath: location.pathname }}>
        <div className={styles.item}>
          {isUpdateVR ? (
            <VRA setIsUpdateVR={setIsUpdateVR} />
          ) : (
            <VR setIsUpdateVR={setIsUpdateVR} />
          )}
          <span
            className={classNames(styles.description, {
              [styles.lightDescription]: light,
            })}
          >
            VR / AR
          </span>
        </div>
      </Link>
      <Link to={'/service/1'} state={{ prevPath: location.pathname }}>
        <div className={styles.item}>
          {isUpdateMuseums ? (
            <MuseumsA setIsUpdateMuseums={setIsUpdateMuseums} />
          ) : (
            <Museums setIsUpdateMuseums={setIsUpdateMuseums} />
          )}
          <span
            className={classNames(styles.description, {
              [styles.lightDescription]: light,
            })}
          >
            музеи / выставки
          </span>
        </div>
      </Link>
      <Link to={'/service/7'} state={{ prevPath: location.pathname }}>
        <div className={styles.item}>
          {isUpdateSites ? (
            <SitesA setIsUpdateSites={setIsUpdateSites} />
          ) : (
            <Sites setIsUpdateSites={setIsUpdateSites} />
          )}
          <span
            className={classNames(styles.description, {
              [styles.lightDescription]: light,
            })}
          >
            сайты
          </span>
        </div>
      </Link>
      <Link to={'/service/4'} state={{ prevPath: location.pathname }}>
        <div className={styles.item}>
          {isUpdateMobileApps ? (
            <MobileAppsA setIsUpdateMobileApps={setIsUpdateMobileApps} />
          ) : (
            <MobileApps setIsUpdateMobileApps={setIsUpdateMobileApps} />
          )}
          <span
            className={classNames(styles.description, {
              [styles.lightDescription]: light,
            })}
          >
            мобильные приложения
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Skills;
