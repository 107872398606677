import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const MuseumsA = ({ setIsUpdateMuseums }) => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <svg
      onMouseEnter={() => setIsUpdateMuseums(true)}
      onMouseLeave={() => setIsUpdateMuseums(false)}
      width='120'
      height='120'
      viewBox='0 0 69 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id='svg979'
      preserveAspectRatio='xMidYMid'
    >
      <defs id='defs9749'></defs>
      <path
        opacity='0.64'
        d=' M 16.1917 46.5523 L 16.1917 46.5523 C 14.1945 44.2638 13.4592 41.9585 13.7645 39.6971 C 14.0702 37.4332 15.4206 35.2015 17.6188 33.0664 L 17.6188 33.0664 C 19.6484 31.0922 22.5521 29.8518 25.3975 29.4616 C 28.2444 29.0712 31.0191 29.5336 32.7973 30.9468 C 36.4506 33.8584 36.3122 40.1277 32.1474 44.6122 C 30.0703 46.8487 26.852 48.2217 23.7624 48.5891 C 20.6697 48.9568 17.729 48.315 16.1917 46.5523 Z '
        fill='white'
        fillOpacity='0.24'
        stroke='white'
        strokeWidth='0.133783'
        id='path7030'
      ></path>
      <path
        opacity='0.64'
        d=' M 16.1613 46.5453 L 16.1612 46.5453 C 14.1722 44.2662 13.4444 41.9763 13.7472 39.7338 C 14.0506 37.4865 15.392 35.2655 17.5852 33.1352 L 17.5852 33.1351 C 19.6049 31.1706 22.497 29.9344 25.333 29.5455 C 28.1718 29.1562 30.929 29.6189 32.691 31.0192 C 36.31 33.9036 36.1867 40.1335 32.035 44.6039 C 29.9687 46.8287 26.7636 48.1973 23.6839 48.5635 C 20.5984 48.9304 17.6809 48.2877 16.1613 46.5453 Z '
        fill='#00B8EA'
        stroke='#00BBE6'
        strokeWidth='0.244094'
        id='path2996'
      ></path>
      <path
        d=' M 25.2308 29.3834 C 25.2308 29.3399 25.3093 27.3029 25.3093 27.3029 L 24.8049 27.2708 L 24.6704 29.5687 L 25.2308 29.3834 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path2724'
      ></path>
      <path
        d=' M 25.0797 27.7046 C 25.3223 27.7046 25.519 27.5055 25.519 27.2599 C 25.519 27.0142 25.3223 26.8151 25.0797 26.8151 C 24.8371 26.8151 24.6404 27.0142 24.6404 27.2599 C 24.6404 27.5055 24.8371 27.7046 25.0797 27.7046 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path9576'
      ></path>
      <path
        d=' M 32.5487 30.9813 C 32.5804 30.951 34.5026 28.7117 34.5026 28.7117 L 34.129 28.3693 L 32.1116 30.62 L 32.5487 30.9813 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path831'
      ></path>
      <path
        d=' M 34.349 28.9833 C 34.5916 28.9833 34.7882 28.7842 34.7882 28.5385 C 34.7882 28.2929 34.5916 28.0938 34.349 28.0938 C 34.1063 28.0938 33.9097 28.2929 33.9097 28.5385 C 33.9097 28.7842 34.1063 28.9833 34.349 28.9833 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path4744'
      ></path>
      <path
        d=' M 35.228 36.9054 C 35.2728 36.9054 38.9135 36.285 38.9135 36.285 L 38.8481 35.78 L 35.2112 36.2283 L 35.228 36.9054 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path8686'
      ></path>
      <path
        d=' M 38.9058 36.4977 C 39.1485 36.4977 39.3451 36.2986 39.3451 36.0529 C 39.3451 35.8073 39.1485 35.6082 38.9058 35.6082 C 38.6632 35.6082 38.4666 35.8073 38.4666 36.0529 C 38.4666 36.2986 38.6632 36.4977 38.9058 36.4977 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path5561'
      ></path>
      <path
        d=' M 34.3124 65.6059 L 34.3124 65.6064 C 34.3194 65.9752 34.0974 66.3344 33.658 66.6726 C 33.2197 67.0099 32.5802 67.3138 31.7919 67.571 C 30.2164 68.085 28.0709 68.4052 25.8195 68.4476 C 23.5724 68.4899 21.4 68.1166 19.7875 67.5282 C 18.9809 67.2338 18.3194 66.8874 17.859 66.5161 C 17.3964 66.1429 17.1525 65.7576 17.1437 65.3865 C 17.135 65.019 17.3584 64.6659 17.803 64.336 C 18.2464 64.007 18.8937 63.7143 19.6907 63.4682 C 21.2833 62.9766 23.448 62.679 25.7038 62.6365 C 27.9554 62.5942 30.1155 62.9446 31.7122 63.5102 C 32.511 63.7932 33.1636 64.1282 33.6159 64.4909 C 34.0702 64.8551 34.3074 65.2345 34.3124 65.6059 Z '
        stroke={light ? 'white' : '#59CFEF'}
        strokeWidth='0.203411'
        id='path5131'
      ></path>
      <path
        d=' M 34.3068 65.5656 C 34.3381 67.3608 30.298 68.6571 25.8556 68.7491 C 21.4133 68.8411 17.2593 67.1277 17.2207 65.3325 C 17.1821 63.5374 21.2883 62.3392 25.7381 62.2472 C 30.1878 62.1552 34.2848 63.7684 34.3068 65.5656 Z '
        fill='#00BBE6'
        stroke='white'
        strokeWidth='0.0989473'
        id='path4026'
      ></path>
      <path
        d=' M 31.2864 44.17 C 31.3181 44.2003 34.2751 46.4396 34.2751 46.4396 L 34.5927 46.0443 L 31.7571 43.6896 L 31.2864 44.17 Z '
        fill='#3E3D3D'
        stroke='white'
        strokeWidth='0.0989473'
        id='path8342'
      ></path>
      <path
        d=' M 34.431 46.7259 C 34.6736 46.7259 34.8703 46.5268 34.8703 46.2811 C 34.8703 46.0355 34.6736 45.8364 34.431 45.8364 C 34.1884 45.8364 33.9917 46.0355 33.9917 46.2811 C 33.9917 46.5268 34.1884 46.7259 34.431 46.7259 Z '
        fill='#3E3D3D'
        stroke='white'
        strokeWidth='0.0989473'
        id='path6168'
      ></path>
      <path
        d=' M 22.783 47.9449 C 22.783 47.9903 22.8427 51.7276 22.8427 51.7276 L 23.3452 51.7371 L 23.4909 47.9203 L 22.783 47.9449 Z '
        fill='#3B3B3B'
        stroke='white'
        strokeWidth='0.0989473'
        id='path690'
      ></path>
      <path
        d=' M 23.0697 52.1992 C 23.3123 52.1992 23.509 52.0001 23.509 51.7544 C 23.509 51.5088 23.3123 51.3096 23.0697 51.3096 C 22.827 51.3096 22.6304 51.5088 22.6304 51.7544 C 22.6304 52.0001 22.827 52.1992 23.0697 52.1992 Z '
        fill='#3B3B3B'
        stroke='white'
        strokeWidth='0.0989473'
        id='path1981'
      ></path>
      <path
        d=' M 15.8905 45.7263 C 15.8569 45.7547 13.2754 48.4271 13.2754 48.4271 L 13.6172 48.796 L 16.4005 46.2181 L 15.8905 45.7263 Z '
        fill='#404040'
        stroke='white'
        strokeWidth='0.0989473'
        id='path2831'
      ></path>
      <path
        d=' M 13.4124 49.056 C 13.655 49.056 13.8517 48.8569 13.8517 48.6112 C 13.8517 48.3656 13.655 48.1664 13.4124 48.1664 C 13.1698 48.1664 12.9731 48.3656 12.9731 48.6112 C 12.9731 48.8569 13.1698 49.056 13.4124 49.056 Z '
        fill='#404040'
        stroke='white'
        strokeWidth='0.0989473'
        id='path6272'
      ></path>
      <path
        d=' M 14.2643 39.5289 C 14.2214 39.5289 10.562 40.032 10.562 40.032 L 10.6124 40.5389 L 14.3727 40.2363 L 14.2643 39.5289 Z '
        fill='#3B3B3B'
        stroke='white'
        strokeWidth='0.0989473'
        id='path736'
      ></path>
      <path
        d=' M 17.9456 33.8906 C 17.9176 33.8641 15.3305 31.8744 15.3305 31.8744 L 15.0503 32.2205 L 17.572 34.3993 L 17.9456 33.8906 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path4579'
      ></path>
      <path
        d=' M 15.2657 32.4823 C 15.5383 32.4823 15.7592 32.2586 15.7592 31.9826 C 15.7592 31.7067 15.5383 31.483 15.2657 31.483 C 14.9932 31.483 14.7722 31.7067 14.7722 31.9826 C 14.7722 32.2586 14.9932 32.4823 15.2657 32.4823 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path7943'
      ></path>
      <path
        d=' M 10.6443 40.7 C 10.8694 40.7 11.0518 40.5153 11.0518 40.2874 C 11.0518 40.0595 10.8694 39.8748 10.6443 39.8748 C 10.4193 39.8748 10.2368 40.0595 10.2368 40.2874 C 10.2368 40.5153 10.4193 40.7 10.6443 40.7 Z '
        fill='#3C3B3B'
        stroke='white'
        strokeWidth='0.0989473'
        id='path6519'
      ></path>
      <path
        d=' M 31.5126 43.9902 C 27.4367 48.3781 18.9972 49.4581 16.0402 46.0518 C 12.2315 41.6658 13.285 37.1285 17.572 32.9486 C 21.5451 29.0714 28.8582 28.0103 32.2859 30.7206 C 35.8126 33.5141 35.5866 39.6042 31.5126 43.9902 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
        id='path1084'
      ></path>
      <path
        d=' M 30.0319 43.0813 C 26.5986 46.7769 19.4928 47.6867 17.001 44.8175 C 13.7918 41.1237 14.7986 37.43 18.2861 33.7816 C 21.5868 30.3204 27.9323 29.2859 30.6801 31.9035 C 33.4278 34.5211 33.4652 39.3856 30.0319 43.0813 Z '
        fill={light ? 'white' : '#00B3E6'}
        opacity={light ? '0.78' : '1'}
        id='path869'
      >
        <animate
          attributeType='auto'
          attributeName='fill'
          values={light ? 'white;#272425;white' : '#00B3E6;white;#00B3E6'}
          calcMode='spline'
          keyTimes='0;0.5;1'
          keySplines='0 0 1 1;0 0 1 1'
          dur='1.2s'
          begin='0s'
          repeatCount='100'
          additive='replace'
          accumulate='none'
          fill='freeze'
          id='animate2192'
        ></animate>
      </path>
      <path
        d=' M 23.8237 65.1489 C 24.457 65.8486 25.7776 65.6917 26.0933 65.0335 V 48.1817 L 23.8237 48.6281 V 65.1489 Z '
        fill='#303030'
        stroke='white'
        strokeWidth='0.0989473'
        id='path267'
      ></path>

      <path
        d=' M 54.5207 28.5692 L 68.0635 34.2546 L 68.1338 10.559 L 54.4451 5.492 L 54.5207 28.5692 Z '
        fill='#272425'
        id='path4327'
      ></path>
      <path
        d=' M 68.0737 10.5259 L 68.4623 10.1703 L 68.5594 33.8517 L 68.1036 34.3037 L 68.0737 10.5259 Z '
        fill='#B1B1B1'
        id='path6919'
      ></path>
      <path
        d=' M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z '
        fill='#272425'
        id='path6766'
      ></path>
      <path
        d=' M 13.9503 20.6809 L 2.38654 23.1183 L 2.2998 2.02809 L 13.9503 1.00018 V 20.6809 Z '
        fill='#00BBE6'
        id='path510'
      ></path>
      <path
        d='M 8.85964 16.6446 C 10.4911 16.0803 12.9355 14.247 13.8945 13.0482 L 13.8945 20.7177 L 2.38621 23.118 C 2.38621 20.3351 2.31733 14.9507 2.30257 14.2386 C 2.30073 14.2238 2.2998 14.2098 2.2998 14.1966 C 2.2998 14.135 2.3008 14.1531 2.30257 14.2386 C 2.40073 15.0266 5.10543 17.943 8.85964 16.6446 Z'
        fill='white'
        fillOpacity='0.65'
        id='path2770'
        transform='matrix(1.0005404340780046 0 0 1.3150424173448982 0.017636622307404204 -3.9512329978434195)'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -2;0 0'
          calcMode='spline'
          keyTimes='0;0.5;1'
          keySplines='0 0 1 1;0 0 1 1'
          dur='1.2s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform5244'
        ></animateTransform>
      </path>
      <path
        d=' M 1.41474 1.31626 L 0.947754 1.24438 V 24.1295 L 1.41288 24.2752 L 1.41474 1.31626 Z '
        fill='#888888'
        id='path3155'
      ></path>
      <path
        d=' M 0.947754 1.2445 L 14.0496 0 L 14.8118 0.068088 L 1.41474 1.31637 L 0.947754 1.2445 Z '
        fill='#888888'
        id='path4880'
      ></path>
      <path
        d=' M 15.9478 0.499939 L 17.7141 0.166443 L 54.0179 5.03852 L 53.7564 5.24846 L 15.9478 0.499939 Z '
        fill='#828282'
        id='path1393'
      ></path>
      <path
        d=' M 53.7572 5.24815 L 54.0187 5.03821 L 53.9645 28.069 L 53.7161 28.3848 L 53.7572 5.24815 Z '
        fill='#929292'
        id='path4326'
      ></path>
      <path
        d='M 55.1595 6.51569 C 55.2261 6.51569 67.4634 11.0018 67.4634 11.0018 L 67.5676 33.1626 L 55.3629 28.0773 L 55.1595 6.51569 Z'
        fill='#00BBE6'
        id='path312'
        transform='matrix(1 0 0 1 0 -0.0625)'
      ></path>
      <path
        d=' M 54.4451 5.49223 L 55.1678 5.28882 L 68.4804 10.1707 L 68.0736 10.5775 L 54.4451 5.49223 Z '
        fill='#7F7F7F'
        id='path3503'
      ></path>

      <path
        d='M 61.066 24.0984 C 57.4896 23.4172 56.3197 18.9958 55.3607 17.7971 L 55.3607 28.1006 L 67.6228 33.2098 C 67.6228 30.093 67.561 21.3913 67.561 21.9667 C 67.561 22.686 64.521 24.7565 61.066 24.0984 Z'
        fill='white'
        fillOpacity='0.65'
        id='path6160'
        transform='matrix(1 0 0 1.1958767421465595 0 -3.0942854479451967)'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -2;0 0'
          calcMode='spline'
          keyTimes='0;0.5;1'
          keySplines='0 0 1 1;0 0 1 1'
          dur='1.2s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform8485'
        ></animateTransform>
      </path>
      <path
        d=' M 17.0754 65.2947 V 66.922 C 17.0754 66.922 18.7399 69.9731 25.1539 69.9731 C 31.5678 69.9731 34.0106 68.1592 34.1659 67.7356 C 34.3212 67.312 34.3074 65.7015 34.3074 65.7015 C 34.2278 67.0762 31.663 68.1424 25.5607 68.5493 C 23.3285 68.4643 21.7036 68.1658 19.8652 67.5322 C 17.8576 66.8891 17.5906 66.374 17.0754 65.2947 Z '
        fill={light ? '#2F2F2F' : 'white'}
        fillOpacity={light ? '1' : '0.6'}
        id='path5008'
      ></path>
      <path
        d=' M 15.9478 0.5 C 16.0187 0.5 53.7649 5.24857 53.7649 5.24857 L 53.7239 28.3853 L 16.4991 21.5311 L 15.9478 0.5 Z '
        fill='#272425'
        id='path4130'
      ></path>
      <path
        d=' M 17.2549 1.44433 L 17.2706 1.44633 L 17.5752 1.48588 C 17.8407 1.52053 18.2283 1.57136 18.7204 1.63606 C 19.7047 1.76545 21.1071 1.9503 22.7882 2.17212 C 26.1503 2.61577 30.6269 3.20729 35.1015 3.79882 C 39.576 4.39035 44.0485 4.98187 47.4023 5.42552 L 51.4545 5.96159 L 52.5896 6.11178 L 52.8887 6.15135 L 52.9206 6.15557 V 26.9447 L 17.5045 20.8825 L 17.2549 1.44433 Z '
        fill='#00BBE6'
        stroke='#00BBE6'
        strokeWidth='0.162729'
        id='path9145'
      ></path>
      <path
        d='M 31.6567 17.8939 C 28.0604 17.1747 26.6218 12.859 24.464 12.1397 C 22.1217 11.359 19.9659 14.0661 17.9478 14.9175 C 17.9478 16.8355 17.9906 21.3779 17.9906 21.3779 C 17.9906 21.3779 41.8135 25.5782 53.5616 27.4963 L 53.4765 18.47 C 52.2777 19.6688 50.5016 22.2096 48.2 22.2096 C 45.3229 22.2096 43.8843 20.0517 41.7265 17.8939 C 39.5687 15.7361 35.2531 18.6132 31.6567 17.8939 Z'
        fill='white'
        id='path2810'
        transform='matrix(1 0 0 1 -0.5662909746170044 -0.47032039760978606)'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -2;0 0'
          calcMode='spline'
          keyTimes='0;0.5;1'
          keySplines='0 0 1 1;0 0 1 1'
          dur='1.2s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform84825'
        ></animateTransform>
      </path>
      <path
        d='M 31.6567 17.8939 C 28.0604 17.1747 26.6218 12.859 24.464 12.1397 C 22.1217 11.359 19.9659 14.0661 17.9478 14.9175 C 17.9478 16.8355 17.9906 21.3779 17.9906 21.3779 C 17.9906 21.3779 41.8135 25.5782 53.5616 27.4963 L 53.4765 18.47 C 52.2777 19.6688 50.5016 22.2096 48.2 22.2096 C 45.3229 22.2096 43.8843 20.0517 41.7265 17.8939 C 39.5687 15.7361 35.2531 18.6132 31.6567 17.8939 Z'
        fill='white'
        transform='matrix(1 0 0 1 -0.4346215307599621 1.7377278804779053)'
        id='path5864'
      ></path>
      <path
        d='M 23.9269 7.20624 C 22.2007 6.63082 18.4068 9.04103 17.4478 10.0001 L 17.4535 20.7838 L 53.002 27.0412 L 53.002 15.8905 C 51.8032 17.0893 49.9645 17.276 47.6628 17.276 C 44.7858 17.276 43.3472 15.1182 41.1894 12.9604 C 39.0316 10.8026 34.716 13.6797 31.1196 12.9604 C 27.5233 12.2411 26.0847 7.92551 23.9269 7.20624 Z'
        fill='white'
        fillOpacity='0.38'
        id='path8430'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -2;0 0'
          calcMode='spline'
          keyTimes='0;0.5;1'
          keySplines='0 0 1 1;0 0 1 1'
          dur='1.2s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform8921'
        ></animateTransform>
      </path>
      <path
        d='M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z'
        fill='#272425'
        transform='matrix(-0.9571131467819214 -0.3885623812675476 -0.01319006085395813 0.04113972187042236 69.30864479541214 33.61340949535934)'
        id='path6134'
      ></path>
      <path
        d='M 15.9478 0.5 C 16.0187 0.5 53.7649 5.24857 53.7649 5.24857 L 53.7239 28.3853 L 16.4991 21.5311 L 15.9478 0.5 Z'
        fill='#272425'
        transform='matrix(0.9849949479103088 0.172588512301445 -0.010863035917282104 0.06199735030531883 0.7209543585777283 17.311575937259825)'
        id='path7458'
      ></path>
      <path
        d='M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z'
        fill='#272425'
        transform='matrix(-0.944200873374939 0.1948208063840866 0.017827395349740982 0.043453119695186615 15.659468650817873 20.29320571423142)'
        id='path3249'
      ></path>
      <path
        d='M 54.5207 28.5692 L 68.0635 34.2546 L 68.1338 10.559 L 54.4451 5.492 L 54.5207 28.5692 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9870632290840149 0.1603451520204544 -0.10456253588199615 0.6436715126037598 2.1513645648956303 16.62677574157715)'
        id='path1325'
      ></path>
      <path
        d='M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9830948114395142 -0.15487055480480194 0.09480227530002594 0.4248824119567871 -0.1279947698059838 23.901689076418002)'
        id='path5889'
      ></path>
      <path
        d='M 15.9478 0.5 C 16.0187 0.5 53.7649 5.24857 53.7649 5.24857 L 53.7239 28.3853 L 16.4991 21.5311 L 15.9478 0.5 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9838485717773438 0.16133137047290802 -0.03668415546417236 0.1541094332933426 1.5079946517944345 18.646788644779356)'
        id='path4470'
      ></path>

      <path
        d='M32.6728,44.3543c.04-.3345.0146-.6242-.0762-.8689-.0897-.2447-.2278-.4378-.4151-.5802-.1858-.1421-.4025-.2283-.6502-.2579s-.4791.0029-.6948.097c-.214.0943-.3938.2494-.5403.4658-.1444.2161-.2369.4916-.277.8267s-.0151.6241.0746.8687c.0909.2443.2294.438.4152.5802.1868.1422.4046.2285.6523.2581s.4786-.0029.6926-.0972c.2152-.0942.3954-.2492.5404-.4658.146-.2164.2385-.4919.2785-.8265Zm.4798.0574c-.0488.4077-.1688.7506-.3602,1.0293-.1913.2786-.4327.4816-.7235.61s-.6111.1722-.96.1304c-.349-.0417-.6499-.1593-.9021-.3532-.2524-.1935-.4389-.4482-.5591-.7641-.1203-.3154-.156-.6775-.1072-1.0851s.1689-.7506.3602-1.0292c.1913-.2782.4327-.4817.7236-.6106.2908-.1279.6104-.1713.9594-.1295s.6498.1594.9021.3533c.2523.1934.4389.4481.5591.764.1208.3155.1565.6771.1077,1.0847Zm-7.0469,1.1172l.4626-3.8657l2.4697.2956-.0497.4155-1.9743-.2363-.1563,1.3059l1.8461.221-.0498.4155-1.846-.2209-.1572,1.3137l2.006.2401-.0498.4155-2.5013-.2999Zm-3.4209-3.9094l.0496-.4151l3.069.3673-.0497.4155-1.2868-.154-.4129,3.4502-.4954-.0593.413-3.4507-1.2868-.1539Zm-.7205-.5072l-.4626,3.8657-.4954-.0593.4626-3.8657.4954.0593Z'
        transform='translate(-3.255369-5.0717)'
        fill='#fff'
      />
      <g
        transform='translate(-3.255368-5.0717)'
        filter='url(#epquDvHaLio45-filter)'
      >
        <path
          d='M32.6729,44.3543c.04-.3345.0146-.6242-.0762-.8689-.0898-.2447-.2278-.4378-.4152-.5802-.1857-.1421-.4024-.2283-.6501-.2579s-.4792.0029-.6948.097c-.2141.0943-.3938.2494-.5403.4658-.1444.2161-.2369.4916-.277.8267s-.0152.6241.0746.8687c.0909.2443.2294.438.4152.5802.1868.1422.4045.2285.6522.2581s.4786-.0029.6927-.0972c.2151-.0942.3954-.2492.5403-.4658.146-.2164.2385-.4919.2786-.8265Zm.4798.0574c-.0488.4077-.1689.7506-.3602,1.0293-.1914.2786-.4327.4816-.7235.61-.2909.1284-.6111.1722-.9601.1304-.3489-.0417-.6498-.1593-.9021-.3532-.2523-.1935-.4388-.4482-.5591-.7641-.1202-.3154-.1559-.6775-.1071-1.0851s.1688-.7506.3602-1.0292c.1913-.2782.4326-.4817.7235-.6106.2908-.1279.6105-.1713.9594-.1295s.6499.1594.9022.3533c.2523.1934.4388.4481.559.764.1208.3155.1566.6771.1078,1.0847Zm-7.0469,1.1172l.4626-3.8657l2.4697.2956-.0497.4155-1.9744-.2363-.1562,1.3059l1.846.221-.0497.4155-1.8461-.2209-.1572,1.3137l2.006.2401-.0497.4155-2.5013-.2999Zm-3.421-3.9094l.0497-.4151l3.069.3673-.0498.4155-1.2867-.154-.4129,3.4502-.4954-.0593.4129-3.4507-1.2868-.1539Zm-.7204-.5072l-.4626,3.8657-.4954-.0593.4626-3.8657.4954.0593Z'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export default MuseumsA;
