import React, { useState } from 'react';
import VkIcon from '../../../assets/image/vk.svg';
import TgIcon from '../../../assets/image/tg.svg';
import WhatsAppIcon from '../../../assets/image/whatsap.svg';

import styles from './SocialLink.module.scss';

const SocialLink = () => {
  return (
    <div className={styles.container}>
      <a className={styles.tel} href='tel:+79138396909'>
        +7 913 839 6909
      </a>
      <a target='_blank' href='https://vk.com/iteo_krsk' className={styles.link}>
        <img src={VkIcon} alt='vkontakte' />
      </a>
      <a target='_blank' href='https://t.me/neymansan' className={styles.link}>
        <img src={TgIcon} alt='telegram' />
      </a>
      <a target='_blank' href='https://wa.me/79138396909' className={styles.link}>
        <img src={WhatsAppIcon} alt='whatsapp' />
      </a>
    </div>
  );
};

export default SocialLink;
