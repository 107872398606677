import React, { useEffect } from 'react';

import styles from './FullScreenImage.module.scss';

import nextArrow from '../../../assets/image/nextArrow.svg';
import prevArrow from '../../../assets/image/prevArrow.svg';
import closeIcon from '../../../assets/image/close.svg';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

const FullScreenImage = ({
  currentImg,
  next,
  prev,
  close,
  isNext,
  isPrev,
  isHave360,
  photo360,
}) => {
  const width = useWindowWidth();
  return (
    <div className={styles.modalWrapper}>
      <img src={closeIcon} alt='' className={styles.close} onClick={close} />
      {isPrev && (
        <img src={prevArrow} alt='' className={styles.prev} onClick={prev} />
      )}
      {isHave360 ? (
        isNext ? (
          <img
            style={width > 768 ? { height: '100%' } : { width: '100%' }}
            src={currentImg}
            alt=''
          />
        ) : (
          // <iframe
          //   style={{
          //     border: 'none',
          //     height: width > 768 ? '100%' : '50%',
          //     width: '100%',
          //   }}
            // src={photo360}
          // ></iframe>
          // <div style={{ width: '800px', height: '500px' }}>
          <ReactPhotoSphereViewer
            // littlePlanet={true}
            src={photo360}
            height={'65vh'}
            width={'85vw'}
          ></ReactPhotoSphereViewer>
          // </div>
        )
      ) : (
        <img
          style={width > 768 ? { height: '100%' } : { width: '100%' }}
          src={currentImg}
          alt=''
        />
      )}
      {isNext && (
        <img src={nextArrow} alt='' className={styles.next} onClick={next} />
      )}
    </div>
  );
};

export default FullScreenImage;
