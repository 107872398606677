import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';

import styles from './Main.module.scss';
import Slider from '../../elements/Slider/Slider';
import Skills from '../../elements/Skills/Skills';
import Toggle from '../../elements/Toggle/Toggle';
import Snowfall from 'react-snowfall';
import { StateContext } from '../../../context/StateContext';

const Main = () => {
  const { isSnow } = useContext(StateContext);

  return (
    <>
      <Helmet>
        <title>Итео</title>
        <meta
          name='description'
          content='Интерактивные инсталляции для музеев и выставок.'
        />
      </Helmet>
      {isSnow && (
        <Snowfall
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            zIndex: 100,
          }}
        />
      )}
      <PageTemplate>
        <Slider />
        <Container width={80}>
          <section className={styles.portfolio}>
            <Skills />
            <div className={styles.footer}>
              <p className={styles.textFooter}>
                {/* работаем <br />с 2014 */}
                <span className={styles.firstStr}>
                  Занимаемся разработкой программного обеспечения с 2014 года
                </span>
                <br/>
                <span className={styles.secondStr}>
                (презентационное, видеоигры, мобильные приложения и пр.)
                </span>
              </p>
              <div className={styles.toggle}>
                <Toggle />
              </div>
            </div>
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Main;
