import styles from '../Astafiev.module.scss';

import track1 from '../astafiev/01PIU.mp3'
import track2 from '../astafiev/02CR.mp3'
import track3 from '../astafiev/03VO.mp3'
import track4 from '../astafiev/04KSRG.mp3'
import { useRef, useState } from 'react';
import { AudioProgressBar } from './AudioProgressBar';
import { AudioButtons } from './AudioButtons';


export const AudioPlayer = ({content}) => {
    const audioRef = useRef(null)
    const [ duration, setDuration ] = useState(0)
    const [ isReady, setIsReady ] = useState(false)
    const [ isPlaying, setIsPlaying ] = useState(false)
    const [ currentProgress, setCurrentProgress ] = useState(0)





    const track = {
        'PIU': track1,
        'CR': track2,
        'VO': track3,
        'KSRG': track4,
    }

    return <div className={styles.audioPlayer}>
        <audio
            preload='metadata'
            ref={audioRef}
            onDurationChange={e => setDuration(e.currentTarget.duration)}
            onCanPlay={() => setIsReady(true)}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onTimeUpdate={e => {
                setCurrentProgress(e.currentTarget.currentTime)
            }}
        >
            <source type='audio/mpeg' src={track[content]} />
        </audio>
        

        <AudioProgressBar
            currentProgress={currentProgress}
            duration={duration}
            buffered={30}
            onChange={e => {
                if (!audioRef.current) return;
                audioRef.current.currentTime = e.currentTarget.valueAsNumber;
                setCurrentProgress(e.currentTarget.valueNumber);
            }}
            audioRef={audioRef}
            setCurrentProgress={setCurrentProgress}
            />
        <AudioButtons
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            audioRef={audioRef}
            setCurrentProgress={setCurrentProgress}
            currentProgress={currentProgress}
            />

    </div>
}
