import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const SitesA = ({ setIsUpdateSites }) => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <svg
      onMouseEnter={() => setIsUpdateSites(true)}
      onMouseLeave={() => setIsUpdateSites(false)}
      width='120'
      height='120'
      viewBox='0 0 103 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id='svg9047'
      preserveAspectRatio='xMidYMid'
    >
      <path
        d=' M 69.4272 44.093 V 3.17359 C 69.4272 1.42087 68.0064 0 66.2537 0 L 3.38444 0 C 1.63171 0 0.210846 1.42087 0.210846 3.17359 V 44.093 C 0.210846 45.8458 1.63171 47.2666 3.38444 47.2666 L 66.2537 47.2666 C 68.0064 47.2666 69.4272 45.8458 69.4272 44.093 Z '
        fill={light ? '#272425' : 'black'}
        id='path254'
        stroke={light ? '' : '#00BBE6'}
        strokeWidth='2'
      ></path>
      <path
        d=' M 45.6407 52.7843 H 24.863 L 26.425 47.2664 H 44.0788 L 45.6407 52.7843 Z '
        fill={light ? '#272425' : 'black'}
        stroke={light ? '' : '#00BBE6'}
        strokeWidth='2'
        id='path5210'
      ></path>
      <path
        d=' M 47.9438 55.8587 H 22.5606 C 22.1544 55.8565 21.7656 55.6936 21.4791 55.4056 C 21.1927 55.1176 21.0319 54.7279 21.0319 54.3217 C 21.0319 54.1196 21.0717 53.9195 21.1491 53.7328 C 21.2265 53.5462 21.34 53.3766 21.483 53.2338 C 21.626 53.0911 21.7958 52.9779 21.9826 52.9008 C 22.1694 52.8238 22.3696 52.7843 22.5717 52.7846 H 47.9549 C 48.3625 52.7846 48.7535 52.9466 49.0417 53.2348 C 49.33 53.5231 49.4919 53.914 49.4919 54.3217 C 49.4919 54.5245 49.4518 54.7252 49.3739 54.9124 C 49.2959 55.0996 49.1817 55.2696 49.0378 55.4125 C 48.8939 55.5553 48.7232 55.6683 48.5354 55.7449 C 48.3477 55.8215 48.1466 55.8602 47.9438 55.8587 Z '
        fill={light ? '#d9d9d9' : 'black'}
        stroke={light ? '' : '#00BBE6'}
        strokeWidth='2'
        id='path9913'
      ></path>
      <path
        d=' M 0 38.7689 V 44.0933 C 0 44.935 0.33436 45.7422 0.929524 46.3373 C 1.52469 46.9325 2.3319 47.2669 3.17359 47.2669 H 66.0428 C 66.8845 47.2669 67.6917 46.9325 68.2869 46.3373 C 68.882 45.7422 69.2164 44.935 69.2164 44.0933 V 38.7689 H 0 Z '
        fill={light ? '#d9d9d9' : 'black'}
        stroke={light ? '' : '#00BBE6'}
        strokeWidth='2'
        id='path1889'
      ></path>
      <path
        d=' M 66.4874 42.8378 C 66.488 43.079 66.417 43.315 66.2833 43.5158 C 66.1497 43.7167 65.9595 43.8734 65.7368 43.9661 C 65.5141 44.0588 65.2688 44.0833 65.0322 44.0366 C 64.7955 43.9899 64.578 43.874 64.4072 43.7036 C 64.2365 43.5332 64.1201 43.316 64.0728 43.0794 C 64.0256 42.8428 64.0495 42.5976 64.1417 42.3747 C 64.2339 42.1517 64.3902 41.9612 64.5907 41.8271 C 64.7913 41.693 65.0271 41.6214 65.2683 41.6214 C 65.4283 41.6211 65.5868 41.6523 65.7346 41.7132 C 65.8825 41.7742 66.017 41.8637 66.1302 41.9767 C 66.2434 42.0897 66.3333 42.2239 66.3946 42.3717 C 66.4559 42.5194 66.4874 42.6778 66.4874 42.8378 Z '
        fill='#272425'
        stroke='white'
        strokeWidth='0.595398'
        strokeMiterlimit='10'
        id='path1165'
      ></path>
      <path
        d=' M 68 3.253 V 36 L 3 36 V 3.253 C 3 2.92069 3.1331 2.60198 3.37003 2.367 C 3.60696 2.13201 3.9283 2 4.26337 2 L 66.7366 2 C 67.0717 2 67.393 2.13201 67.63 2.367 C 67.8669 2.60198 68 2.92069 68 3.253 Z '
        fill={light ? 'white' : 'black'}
        fillOpacity='0.46'
        id='path7229'
      ></path>
      <g style={{ mixBlendMode: 'screen' }} id='g8241'>
        <path
          d=' M 40.3045 41.8456 H 32.5981 C 32.5219 41.8456 32.4488 41.7843 32.3949 41.6753 C 32.341 41.5663 32.3107 41.4185 32.3107 41.2643 C 32.3107 41.1102 32.341 40.9623 32.3949 40.8533 C 32.4488 40.7443 32.5219 40.6831 32.5981 40.6831 H 40.3045 C 40.3807 40.6831 40.4538 40.7443 40.5077 40.8533 C 40.5616 40.9623 40.5919 41.1102 40.5919 41.2643 C 40.5919 41.4185 40.5616 41.5663 40.5077 41.6753 C 40.4538 41.7843 40.3807 41.8456 40.3045 41.8456 Z '
          fill='white'
          fillOpacity='0.8'
          id='path5725'
        ></path>
      </g>
      <g
        style={{ mixBlendMode: 'screen' }}
        filter='url(#filter0_f_910_305)'
        id='g3880'
      >
        <path
          d=' M 39.8686 43.008 H 32.1622 C 32.086 43.008 32.0129 42.9468 31.9589 42.8378 C 31.905 42.7288 31.8748 42.581 31.8748 42.4268 C 31.8748 42.2727 31.905 42.1248 31.9589 42.0158 C 32.0129 41.9068 32.086 41.8456 32.1622 41.8456 H 39.8686 C 39.9448 41.8456 40.0179 41.9068 40.0718 42.0158 C 40.1257 42.1248 40.156 42.2727 40.156 42.4268 C 40.156 42.581 40.1257 42.7288 40.0718 42.8378 C 40.0179 42.9468 39.9448 43.008 39.8686 43.008 Z '
          fill='white'
          fillOpacity='0.8'
          id='path4142'
        ></path>
      </g>
      <mask
        id='mask0_910_305'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='65'
        height='34'
      >
        <path
          d=' M 68 3.253 V 36 L 3 36 V 3.253 C 3 2.92069 3.13311 2.60198 3.37003 2.367 C 3.60696 2.13201 3.92831 2 4.26337 2 L 66.7366 2 C 67.0717 2 67.393 2.13201 67.63 2.367 C 67.8669 2.60198 68 2.92069 68 3.253 Z '
          fill='white'
          id='path6272'
        ></path>
      </mask>
      <g mask='url(#mask0_910_305)' id='g1598'>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 31.920875549316406 40.49479293823242)'
          id='path9856'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform5932'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 31.988964080810547 20.0053653717041)'
          id='path6747'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform7506'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 16.00520319939789 40.50823211669922)'
          id='path2815'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform5016'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 16.003475189208984 20.075443267822266)'
          id='path8612'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform1436'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 0.03737223148345947 40.452392578125)'
          id='path6871'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform8363'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          transform='matrix(1 0 0 1 -0.06912950574787047 20.017993927001953)'
          id='path9147'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform5781'
          ></animateTransform>
        </path>
        <path
          d='M 26 10 L 13 10 L 13 28 L 26 28 L 26 10 Z'
          fill='white'
          fillOpacity='0.33'
          id='path6876'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform347'
          ></animateTransform>
        </path>

        <path
          d='M 58 10 L 45 10 L 45 28 L 58 28 L 58 10 Z'
          fill='white'
          fillOpacity='0.33'
          id='path9176'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0; 1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform9487'
          ></animateTransform>
        </path>

        <path
          d='M 42 10 L 29 10 L 29 28 L 42 28 L 42 10 Z'
          fill='white'
          fillOpacity='0.33'
          id='path989'
        >
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;0 -20'
            calcMode='spline'
            keyTimes='0;1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform7869'
          ></animateTransform>
        </path>
        <g filter='url(#filter1_b_910_305)' id='g8496'>
          <path
            d=' M 69 1 H 2 V 8 H 69 V 1 Z '
            fill='#00B8EA'
            id='path8287'
          ></path>
        </g>
        <path
          d=' M 15.7546 6.22378 C 15.0634 6.90295 13.9427 6.90295 13.2515 6.22378 C 12.5603 5.5446 12.5603 4.44345 13.2515 3.76427 C 13.9427 3.0851 15.0634 3.0851 15.7546 3.76427 C 16.4458 4.44345 16.4458 5.5446 15.7546 6.22378 Z '
          stroke='white'
          strokeWidth='0.595398'
          strokeMiterlimit='10'
          id='path2664'
        ></path>
        <g style={{ mixBlendMode: 'screen' }} id='g7003'>
          <path
            d=' M 36.8068 5.20026 H 30.3865 C 30.2234 5.19838 30.0676 5.13337 29.9529 5.01934 C 29.8382 4.90531 29.7739 4.75146 29.7739 4.59114 C 29.7739 4.43023 29.839 4.2759 29.9548 4.16212 C 30.0706 4.04834 30.2276 3.98441 30.3914 3.98441 H 36.8117 C 36.9757 3.98441 37.133 4.04826 37.2492 4.16197 C 37.3654 4.27569 37.4309 4.43001 37.4316 4.59114 C 37.4316 4.67154 37.4154 4.75114 37.3839 4.82535 C 37.3525 4.89957 37.3064 4.96693 37.2483 5.02355 C 37.1902 5.08018 37.1213 5.12495 37.0455 5.15527 C 36.9698 5.1856 36.8886 5.20089 36.8068 5.20026 Z '
            fill='white'
            fillOpacity='0.85'
            id='path5231'
          ></path>
        </g>
        <g style={{ mixBlendMode: 'screen' }} id='g4358'>
          <path
            d=' M 26.0329 5.20026 H 19.6126 C 19.4495 5.19838 19.2937 5.13337 19.179 5.01934 C 19.0643 4.90531 19 4.75146 19 4.59114 C 19 4.43023 19.0651 4.2759 19.1809 4.16212 C 19.2967 4.04834 19.4537 3.98441 19.6175 3.98441 H 26.0378 C 26.2017 3.98441 26.3591 4.04826 26.4752 4.16197 C 26.5914 4.27569 26.657 4.43001 26.6577 4.59114 C 26.6577 4.67154 26.6415 4.75114 26.61 4.82535 C 26.5786 4.89957 26.5325 4.96693 26.4744 5.02355 C 26.4163 5.08018 26.3474 5.12495 26.2716 5.15527 C 26.1958 5.1856 26.1147 5.20089 26.0329 5.20026 Z '
            fill='white'
            fillOpacity='0.85'
            id='path9451'
          ></path>
        </g>
        <g style={{ mixBlendMode: 'screen' }} id='g9111'>
          <path
            d=' M 47.5807 5.20026 H 41.1605 C 40.9973 5.19838 40.8415 5.13337 40.7268 5.01934 C 40.6121 4.90531 40.5478 4.75146 40.5479 4.59114 C 40.5479 4.43023 40.6129 4.2759 40.7287 4.16212 C 40.8445 4.04834 41.0016 3.98441 41.1653 3.98441 H 47.5856 C 47.7496 3.98441 47.9069 4.04826 48.0231 4.16197 C 48.1393 4.27569 48.2049 4.43001 48.2055 4.59114 C 48.2055 4.67154 48.1893 4.75114 48.1579 4.82535 C 48.1264 4.89957 48.0803 4.96693 48.0222 5.02355 C 47.9642 5.08018 47.8952 5.12495 47.8195 5.15527 C 47.7437 5.1856 47.6626 5.20089 47.5807 5.20026 Z '
            fill='white'
            fillOpacity='0.85'
            id='path8065'
          ></path>
        </g>
        <g style={{ mixBlendMode: 'screen' }} id='g2226'>
          <path
            d=' M 57.3752 5.20026 H 50.9549 C 50.7918 5.19838 50.6359 5.13337 50.5213 5.01934 C 50.4066 4.90531 50.3423 4.75146 50.3423 4.59114 C 50.3423 4.43023 50.4073 4.2759 50.5231 4.16212 C 50.6389 4.04834 50.796 3.98441 50.9598 3.98441 H 57.38 C 57.544 3.98441 57.7013 4.04826 57.8175 4.16197 C 57.9337 4.27569 57.9993 4.43001 58 4.59114 C 58 4.67154 57.9838 4.75114 57.9523 4.82535 C 57.9208 4.89957 57.8747 4.96693 57.8167 5.02355 C 57.7586 5.08018 57.6897 5.12495 57.6139 5.15527 C 57.5381 5.1856 57.457 5.20089 57.3752 5.20026 Z '
            fill='white'
            fillOpacity='0.85'
            id='path1951'
          ></path>
        </g>
      </g>

      <path
        d=' M 98.8822 28.5794 H 58.399 C 57.6217 28.567 56.8712 28.8623 56.3126 29.4001 C 55.754 29.938 55.433 30.6746 55.42 31.4479 V 53.0877 C 55.425 53.4715 55.5061 53.8506 55.6586 54.2032 C 55.8111 54.5557 56.0321 54.8749 56.3088 55.1424 C 56.5856 55.4099 56.9126 55.6204 57.2713 55.7619 C 57.63 55.9034 58.0132 55.9731 58.399 55.967 H 98.8822 C 99.6595 55.9793 100.41 55.6841 100.969 55.1463 C 101.527 54.6084 101.848 53.8718 101.861 53.0985 V 31.4479 C 101.848 30.6746 101.527 29.938 100.969 29.4001 C 100.41 28.8623 99.6595 28.567 98.8822 28.5794 Z '
        stroke={light ? 'black' : 'white'}
        strokeWidth={light ? '1' : '2'}
        strokeMiterlimit='10'
        id='path2511'
      ></path>
      <path
        d=' M 98.8822 28.5794 H 58.399 C 57.6217 28.567 56.8712 28.8623 56.3126 29.4001 C 55.754 29.938 55.433 30.6746 55.42 31.4479 V 53.0877 C 55.425 53.4715 55.5061 53.8506 55.6586 54.2032 C 55.8111 54.5557 56.0321 54.8749 56.3088 55.1424 C 56.5856 55.4099 56.9126 55.6204 57.2713 55.7619 C 57.63 55.9034 58.0132 55.9731 58.399 55.967 H 98.8822 C 99.6595 55.9793 100.41 55.6841 100.969 55.1463 C 101.527 54.6084 101.848 53.8718 101.861 53.0985 V 31.4479 C 101.848 30.6746 101.527 29.938 100.969 29.4001 C 100.41 28.8623 99.6595 28.567 98.8822 28.5794 Z '
        stroke={light ? 'black' : 'white'}
        strokeOpacity='0.2'
        strokeMiterlimit='10'
        id='path6070'
      ></path>
      <path
        d=' M 98.8822 28.5794 H 58.399 C 57.6217 28.567 56.8712 28.8623 56.3126 29.4001 C 55.754 29.938 55.433 30.6746 55.42 31.4479 V 53.0877 C 55.425 53.4715 55.5061 53.8506 55.6586 54.2032 C 55.8111 54.5557 56.0321 54.8749 56.3088 55.1424 C 56.5856 55.4099 56.9126 55.6204 57.2713 55.7619 C 57.63 55.9034 58.0132 55.9731 58.399 55.967 H 98.8822 C 99.6595 55.9793 100.41 55.6841 100.969 55.1463 C 101.527 54.6084 101.848 53.8718 101.861 53.0985 V 31.4479 C 101.848 30.6746 101.527 29.938 100.969 29.4001 C 100.41 28.8623 99.6595 28.567 98.8822 28.5794 Z '
        fill='black'
        id='path7445'
      ></path>
      <path
        d=' M 57 52.9999 H 100 V 35.9999 H 78.5 H 57 V 52.9999 Z '
        fill='white'
        fillOpacity='0.42'
        id='path2386'
      ></path>
      <path
        d='M 94 37.9999 L 85 37.9999 L 85 48.9999 L 94 48.9999 L 94 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 -0.023077381309121847 26.124868392944336)'
        id='path3651'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform1619'
        ></animateTransform>
      </path>
      <path
        d='M 94 37.9999 L 85 37.9999 L 85 48.9999 L 94 48.9999 L 94 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 0.015127427875995636 12.94839859008789)'
        id='path7946'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform2822'
        ></animateTransform>
      </path>
      <path
        d='M 94 37.9999 L 85 37.9999 L 85 48.9999 L 94 48.9999 L 94 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        id='path4209'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -15'
          calcMode='spline'
          keyTimes='0; 1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform321'
        ></animateTransform>
      </path>
      <path
        d='M 83 37.9999 L 75 37.9999 L 75 48.9999 L 83 48.9999 L 83 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 -0.061384931206703186 26.15448833703713)'
        id='path8929'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform6583'
        ></animateTransform>
      </path>
      <path
        d='M 83 37.9999 L 75 37.9999 L 75 48.9999 L 83 48.9999 L 83 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 -0.01859804056584835 12.956412315368652)'
        id='path8423'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform897'
        ></animateTransform>
      </path>
      <path
        d='M 83 37.9999 L 75 37.9999 L 75 48.9999 L 83 48.9999 L 83 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        id='path7871'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -15'
          calcMode='spline'
          keyTimes='0; 1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform6435'
        ></animateTransform>
      </path>
      <path
        d='M 72 37.9999 L 64 37.9999 L 64 48.9999 L 72 48.9999 L 72 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 0.056232087314128876 12.95825481414795)'
        id='path1062'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform7878'
        ></animateTransform>
      </path>
      <path
        d='M 72 37.9999 L 64 37.9999 L 64 48.9999 L 72 48.9999 L 72 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        transform='matrix(1 0 0 1 0.05442041531205177 26.069503784179688)'
        id='path3955'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -13'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform2673'
        ></animateTransform>
      </path>
      <path
        d='M 72 37.9999 L 64 37.9999 L 64 48.9999 L 72 48.9999 L 72 37.9999 Z'
        fill='white'
        fillOpacity='0.26'
        id='path7862'
      >
        <animateTransform
          attributeName='transform'
          attributeType='auto'
          type='translate'
          values='0 0;0 -15'
          calcMode='spline'
          keyTimes='0;1'
          keySplines='0 0 1 1'
          dur='0.3s'
          begin='0s'
          repeatCount='100'
          additive='sum'
          accumulate='none'
          fill='freeze'
          id='animateTransform6318'
        ></animateTransform>
      </path>
      <path
        d='M99.7319,29.9999h-42.4649c-.1474,0-.267.1196-.267.2671v5.7329h43l-.0122-2.9368.0111-2.7951c.0006-.1478-.1191-.2681-.267-.2681Z'
        transform='matrix(1.178236961364746 0 0 2.806560347659058 -15.09405517578125 -23.776824305051605)'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
      />
      <path
        d='M101.7319,29.9999h-42.4649c-.1474,0-.267.1196-.267.2671v5.7329h43l-.0122-2.9368.0111-2.7951c.0006-.1478-.1191-.2681-.267-.2681Z'
        transform='matrix(.675681 0 0 1 30.913383-7.92)'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
      />
      <path
        d=' M 99.7319 29.9999 H 57.267 C 57.1196 29.9999 57 30.1195 57 30.267 V 35.9999 H 100 L 99.9878 33.0631 L 99.9989 30.268 C 99.9995 30.1202 99.8798 29.9999 99.7319 29.9999 Z '
        fill='#00C9FF'
        id='path1959'
      ></path>
      <path
        d=' M 66.3356 34.0666 C 66.9496 33.502 66.9496 32.5867 66.3356 32.0222 C 65.7215 31.4576 64.7259 31.4576 64.1119 32.0222 C 63.4978 32.5867 63.4978 33.502 64.1119 34.0666 C 64.7259 34.6311 65.7215 34.6311 66.3356 34.0666 Z '
        stroke='white'
        strokeWidth='0.216256'
        strokeMiterlimit='10'
        id='path7495'
      ></path>
      <g style={{ mixBlendMode: 'screen' }} id='g4340'>
        <path
          d=' M 72.5959 32.8316 H 68.4032 C 68.3501 32.8316 68.2976 32.822 68.2486 32.8032 C 68.1995 32.7845 68.155 32.7571 68.1175 32.7225 C 68.0801 32.6879 68.0504 32.6469 68.0302 32.6017 C 68.0101 32.5566 67.9998 32.5082 68 32.4594 C 68 32.3611 68.0425 32.2668 68.1181 32.1973 C 68.1937 32.1277 68.2963 32.0887 68.4032 32.0887 H 72.5959 C 72.7029 32.0887 72.8057 32.1277 72.8815 32.1972 C 72.9574 32.2667 73.0002 32.361 73.0007 32.4594 C 73.0007 32.5581 72.958 32.6528 72.8821 32.7226 C 72.8062 32.7924 72.7032 32.8316 72.5959 32.8316 Z '
          fill='white'
          fillOpacity='0.85'
          id='path8810'
        ></path>
      </g>
      <g style={{ mixBlendMode: 'screen' }} id='g5487'>
        <path
          d=' M 79.4899 32.8316 H 75.2973 C 75.1907 32.8304 75.089 32.7907 75.0141 32.721 C 74.9392 32.6514 74.8972 32.5574 74.8972 32.4594 C 74.8972 32.3611 74.9397 32.2668 75.0153 32.1973 C 75.0909 32.1277 75.1935 32.0887 75.3004 32.0887 H 79.4931 C 79.6002 32.0887 79.7029 32.1277 79.7788 32.1972 C 79.8546 32.2667 79.8975 32.361 79.8979 32.4594 C 79.8979 32.5085 79.8873 32.5572 79.8668 32.6025 C 79.8462 32.6479 79.8161 32.689 79.7782 32.7236 C 79.7403 32.7582 79.6953 32.7856 79.6458 32.8041 C 79.5963 32.8226 79.5433 32.832 79.4899 32.8316 Z '
          fill='white'
          fillOpacity='0.85'
          id='path2157'
        ></path>
      </g>
      <g style={{ mixBlendMode: 'screen' }} id='g3194'>
        <path
          d=' M 86.7008 32.8316 H 82.5082 C 82.4011 32.8312 82.2986 32.7918 82.223 32.7221 C 82.1474 32.6523 82.105 32.5579 82.105 32.4594 C 82.105 32.3611 82.1475 32.2668 82.2231 32.1973 C 82.2987 32.1277 82.4013 32.0887 82.5082 32.0887 H 86.7008 C 86.8079 32.0887 86.9106 32.1277 86.9865 32.1972 C 87.0624 32.2667 87.1052 32.361 87.1056 32.4594 C 87.1056 32.5581 87.063 32.6528 86.9871 32.7226 C 86.9112 32.7924 86.8082 32.8316 86.7008 32.8316 Z '
          fill='white'
          fillOpacity='0.85'
          id='path5782'
        ></path>
      </g>
      <g style={{ mixBlendMode: 'screen' }} id='g1205'>
        <path
          d=' M 93.5968 32.8316 H 89.4042 C 89.2968 32.8316 89.1939 32.7924 89.118 32.7226 C 89.042 32.6528 88.9994 32.5581 88.9994 32.4594 C 88.9998 32.361 89.0426 32.2667 89.1185 32.1972 C 89.1944 32.1277 89.2971 32.0887 89.4042 32.0887 H 93.5968 C 93.7038 32.0887 93.8063 32.1277 93.8819 32.1973 C 93.9576 32.2668 94.0001 32.3611 94.0001 32.4594 C 94.0001 32.5579 93.9576 32.6523 93.882 32.7221 C 93.8065 32.7918 93.7039 32.8312 93.5968 32.8316 Z '
          fill='white'
          fillOpacity='0.85'
          id='path3742'
        ></path>
      </g>
      <path
        d='M 55.5435 59.2153 L 57.8224 55.9251 C 57.9141 55.7928 58.0639 55.7126 58.2248 55.7099 L 99.2099 55.0054 C 99.4031 55.0021 99.581 55.1104 99.6666 55.2836 L 101.643 59.2783 C 101.807 59.6106 101.566 60 101.195 60 L 55.9545 60 C 55.5512 60 55.3138 59.5469 55.5435 59.2153 Z'
        fill='black'
        stroke={light ? 'black' : 'white'}
        transform='matrix(0.9150299232746519 0 0 0.9259975817210986 7.048090921647201 2.8076206212543298)'
        id='path8914'
      ></path>
      <path
        d=' M 55.5435 59.2153 L 57.8224 55.9251 C 57.9141 55.7928 58.0639 55.7126 58.2248 55.7099 L 99.2099 55.0054 C 99.4031 55.0021 99.581 55.1104 99.6666 55.2836 L 101.643 59.2783 C 101.807 59.6106 101.566 60 101.195 60 H 55.9545 C 55.5512 60 55.3138 59.5469 55.5435 59.2153 Z '
        fill='black'
        stroke={light ? 'black' : 'white'}
        id='path484'
      ></path>

      <defs id='defs6963'>
        <filter
          id='filter0_f_910_305'
          x='27.1116'
          y='37.0824'
          width='17.8076'
          height='10.6888'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
            id='feFlood3267'
          ></feFlood>
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
            id='feBlend9834'
          ></feBlend>
          <feGaussianBlur
            stdDeviation='2.38159'
            result='effect1_foregroundBlur_910_305'
            id='feGaussianBlur905'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter1_b_910_305'
          x='-7.52637'
          y='-8.52637'
          width='86.0527'
          height='26.0527'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
            id='feFlood3105'
          ></feFlood>
          <feGaussianBlur
            in='BackgroundImageFix'
            stdDeviation='4.76319'
            id='feGaussianBlur233'
          ></feGaussianBlur>
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_910_305'
            id='feComposite9467'
          ></feComposite>
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_910_305'
            result='shape'
            id='feBlend9862'
          ></feBlend>
        </filter>
      </defs>
      <path
        d='M 98.8822 28.5794 L 58.399 28.5794 C 57.6217 28.567 56.8712 28.8623 56.3126 29.4001 C 55.754 29.938 55.433 30.6746 55.42 31.4479 L 55.42 53.0877 C 55.425 53.4715 55.5061 53.8506 55.6586 54.2032 C 55.8111 54.5557 56.0321 54.8749 56.3088 55.1424 C 56.5856 55.4099 56.9126 55.6204 57.2713 55.7619 C 57.63 55.9034 58.0132 55.9731 58.399 55.967 L 98.8822 55.967 C 99.6595 55.9793 100.41 55.6841 100.969 55.1463 C 101.527 54.6084 101.848 53.8718 101.861 53.0985 L 101.861 31.4479 C 101.848 30.6746 101.527 29.938 100.969 29.4001 C 100.41 28.8623 99.6595 28.567 98.8822 28.5794 Z'
        fill={light ? 'black' : 'black'}
        stroke='white'
        strokeWidth='0.8595398'
        transform='matrix(0.8823204636573792 0 0 0.06842035307350436 8.705232620239258 26.14570262326015)'
        id='path9362'
      ></path>
      <path
        d='M 98.8822 28.5794 L 58.399 28.5794 C 57.6217 28.567 56.8712 28.8623 56.3126 29.4001 C 55.754 29.938 55.433 30.6746 55.42 31.4479 L 55.42 53.0877 C 55.425 53.4715 55.5061 53.8506 55.6586 54.2032 C 55.8111 54.5557 56.0321 54.8749 56.3088 55.1424 C 56.5856 55.4099 56.9126 55.6204 57.2713 55.7619 C 57.63 55.9034 58.0132 55.9731 58.399 55.967 L 98.8822 55.967 C 99.6595 55.9793 100.41 55.6841 100.969 55.1463 C 101.527 54.6084 101.848 53.8718 101.861 53.0985 L 101.861 31.4479 C 101.848 30.6746 101.527 29.938 100.969 29.4001 C 100.41 28.8623 99.6595 28.567 98.8822 28.5794 Z'
        fill={light ? 'black' : 'black'}
        transform='matrix(0.8823204636573792 0 0 0.08175180852413177 9.448650360107422 50.69578461645905)'
        id='path6191'
      ></path>
      {light && (
        <path
          d='M 69.4272 44.093 L 69.4272 3.17359 C 69.4272 1.42087 68.0064 0 66.2537 0 L 3.38444 0 C 1.63171 0 0.210846 1.42087 0.210846 3.17359 L 0.210846 44.093 C 0.210846 45.8458 1.63171 47.2666 3.38444 47.2666 L 66.2537 47.2666 C 68.0064 47.2666 69.4272 45.8458 69.4272 44.093 Z'
          fill='#272425'
          transform='matrix(0.020336665637995743 0 0 0.305076003074646 67.99545718463969 13.666603088378906)'
          id='path12'
        ></path>
      )}
      <path
        d='M68,3.253L68,36h-65L3,3.253c0-.33231.1331-.65102.37003-.886s.55827-.367.89334-.367h62.47323c.3351,0,.6564.13201.8934.367s.37.55369.37.886Z'
        transform='matrix(.142048 0 0 0.317833 58.340736 16.617983)'
        fill={light ? '#888' : 'black'}
        fillOpacity='1'
      />
    </svg>
  );
};

export default SitesA;
