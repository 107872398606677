import styles from './Astafiev.module.scss';

export const Description = ({content}) => {
    const description = {
        'PIU': 'Главная книга Виктора Петровича Астафьева и одно из ярких произведений литературы советского времени. Повествование объединено одним героем и одной всепоглощающей идеей - идеей неотделимости человека от природы.',
        'CR': 'Один из самых сильных и пронзительных образцов российской «военной» прозы.',
        'VO': 'Таёжная жизнь тринадцатилетнего мальчика Васютки, заблудившегося в лесу. Пять суток он преодолевал страх и трудности одиночества в тайге.',
        'KSRG': 'В книгу входят рассказы о родине писателя - Сибири, о его детстве - этой удивительно светлой и прекрасной поре.',
    }
    return <div className={styles.description}>
        <p>{description[content]}</p>
    </div>
}