import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const Museums = ({ setIsUpdateMuseums }) => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <svg
      onMouseEnter={() => setIsUpdateMuseums(true)}
      onMouseLeave={() => setIsUpdateMuseums(false)}
      width='120'
      height='120'
      viewBox='0 0 69 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.64'
        d='M16.1917 46.5523L16.1917 46.5523C14.1945 44.2638 13.4592 41.9585 13.7645 39.6971C14.0702 37.4332 15.4206 35.2015 17.6188 33.0664L17.6188 33.0664C19.6484 31.0922 22.5521 29.8518 25.3975 29.4616C28.2444 29.0712 31.0191 29.5336 32.7973 30.9468C36.4506 33.8584 36.3122 40.1277 32.1474 44.6122C30.0703 46.8487 26.852 48.2217 23.7624 48.5891C20.6697 48.9568 17.729 48.315 16.1917 46.5523Z'
        fill='white'
        fillOpacity='0.24'
        stroke='white'
        strokeWidth='0.133783'
      />
      <path
        opacity='0.64'
        d='M16.1613 46.5453L16.1612 46.5453C14.1722 44.2662 13.4444 41.9763 13.7472 39.7338C14.0506 37.4865 15.392 35.2655 17.5852 33.1352L17.5852 33.1351C19.6049 31.1706 22.497 29.9344 25.333 29.5455C28.1718 29.1562 30.929 29.6189 32.691 31.0192C36.31 33.9036 36.1867 40.1335 32.035 44.6039C29.9687 46.8287 26.7636 48.1973 23.6839 48.5635C20.5984 48.9304 17.6809 48.2877 16.1613 46.5453Z'
        fill='#00B8EA'
        stroke='#00BBE6'
        strokeWidth='0.244094'
      />
      <path
        d='M25.2308 29.3834C25.2308 29.3399 25.3093 27.3029 25.3093 27.3029L24.8049 27.2708L24.6704 29.5687L25.2308 29.3834Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M25.0797 27.7046C25.3223 27.7046 25.519 27.5055 25.519 27.2599C25.519 27.0142 25.3223 26.8151 25.0797 26.8151C24.8371 26.8151 24.6404 27.0142 24.6404 27.2599C24.6404 27.5055 24.8371 27.7046 25.0797 27.7046Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M32.5487 30.9813C32.5804 30.951 34.5026 28.7117 34.5026 28.7117L34.129 28.3693L32.1116 30.62L32.5487 30.9813Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M34.349 28.9833C34.5916 28.9833 34.7882 28.7842 34.7882 28.5385C34.7882 28.2929 34.5916 28.0938 34.349 28.0938C34.1063 28.0938 33.9097 28.2929 33.9097 28.5385C33.9097 28.7842 34.1063 28.9833 34.349 28.9833Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M35.228 36.9054C35.2728 36.9054 38.9135 36.285 38.9135 36.285L38.8481 35.78L35.2112 36.2283L35.228 36.9054Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M38.9058 36.4977C39.1485 36.4977 39.3451 36.2986 39.3451 36.0529C39.3451 35.8073 39.1485 35.6082 38.9058 35.6082C38.6632 35.6082 38.4666 35.8073 38.4666 36.0529C38.4666 36.2986 38.6632 36.4977 38.9058 36.4977Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M34.3124 65.6059L34.3124 65.6064C34.3194 65.9752 34.0974 66.3344 33.658 66.6726C33.2197 67.0099 32.5802 67.3138 31.7919 67.571C30.2164 68.085 28.0709 68.4052 25.8195 68.4476C23.5724 68.4899 21.4 68.1166 19.7875 67.5282C18.9809 67.2338 18.3194 66.8874 17.859 66.5161C17.3964 66.1429 17.1525 65.7576 17.1437 65.3865C17.135 65.019 17.3584 64.6659 17.803 64.336C18.2464 64.007 18.8937 63.7143 19.6907 63.4682C21.2833 62.9766 23.448 62.679 25.7038 62.6365C27.9554 62.5942 30.1155 62.9446 31.7122 63.5102C32.511 63.7932 33.1636 64.1282 33.6159 64.4909C34.0702 64.8551 34.3074 65.2345 34.3124 65.6059Z'
        stroke={light ? 'white' : '#59CFEF'}
        strokeWidth='0.203411'
      />
      <path
        d='M34.3068 65.5656C34.3381 67.3608 30.298 68.6571 25.8556 68.7491C21.4133 68.8411 17.2593 67.1277 17.2207 65.3325C17.1821 63.5374 21.2883 62.3392 25.7381 62.2472C30.1878 62.1552 34.2848 63.7684 34.3068 65.5656Z'
        fill='#00BBE6'
      />
      <path
        d='M31.2864 44.17C31.3181 44.2003 34.2751 46.4396 34.2751 46.4396L34.5927 46.0443L31.7571 43.6896L31.2864 44.17Z'
        fill='#3E3D3D'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M34.431 46.7259C34.6736 46.7259 34.8703 46.5268 34.8703 46.2811C34.8703 46.0355 34.6736 45.8364 34.431 45.8364C34.1884 45.8364 33.9917 46.0355 33.9917 46.2811C33.9917 46.5268 34.1884 46.7259 34.431 46.7259Z'
        fill='#3E3D3D'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M22.783 47.9449C22.783 47.9903 22.8427 51.7276 22.8427 51.7276L23.3452 51.7371L23.4909 47.9203L22.783 47.9449Z'
        fill='#3E3D3D'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M23.0697 52.1992C23.3123 52.1992 23.509 52.0001 23.509 51.7544C23.509 51.5088 23.3123 51.3096 23.0697 51.3096C22.827 51.3096 22.6304 51.5088 22.6304 51.7544C22.6304 52.0001 22.827 52.1992 23.0697 52.1992Z'
        fill='#3B3B3B'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M15.8905 45.7263C15.8569 45.7547 13.2754 48.4271 13.2754 48.4271L13.6172 48.796L16.4005 46.2181L15.8905 45.7263Z'
        fill='#404040'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M13.4124 49.056C13.655 49.056 13.8517 48.8569 13.8517 48.6112C13.8517 48.3656 13.655 48.1664 13.4124 48.1664C13.1698 48.1664 12.9731 48.3656 12.9731 48.6112C12.9731 48.8569 13.1698 49.056 13.4124 49.056Z'
        fill='#404040'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M14.2643 39.5289C14.2214 39.5289 10.562 40.032 10.562 40.032L10.6124 40.5389L14.3727 40.2363L14.2643 39.5289Z'
        fill='#3B3B3B'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M17.9456 33.8906C17.9176 33.8641 15.3305 31.8744 15.3305 31.8744L15.0503 32.2205L17.572 34.3993L17.9456 33.8906Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M15.2657 32.4823C15.5383 32.4823 15.7592 32.2586 15.7592 31.9826C15.7592 31.7067 15.5383 31.483 15.2657 31.483C14.9932 31.483 14.7722 31.7067 14.7722 31.9826C14.7722 32.2586 14.9932 32.4823 15.2657 32.4823Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M10.6443 40.7C10.8694 40.7 11.0518 40.5153 11.0518 40.2874C11.0518 40.0595 10.8694 39.8748 10.6443 39.8748C10.4193 39.8748 10.2368 40.0595 10.2368 40.2874C10.2368 40.5153 10.4193 40.7 10.6443 40.7Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M31.5126 43.9902C27.4367 48.3781 18.9972 49.4581 16.0402 46.0518C12.2315 41.6658 13.285 37.1285 17.572 32.9486C21.5451 29.0714 28.8582 28.0103 32.2859 30.7206C35.8126 33.5141 35.5866 39.6042 31.5126 43.9902Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M30.0319 43.0813C26.5986 46.7769 19.4928 47.6867 17.001 44.8175C13.7918 41.1237 14.7986 37.43 18.2861 33.7816C21.5868 30.3204 27.9323 29.2859 30.6801 31.9035C33.4278 34.5211 33.4652 39.3856 30.0319 43.0813Z'
        fill={light ? 'white' : '#00B3E6'}
        fillOpacity={light ? '0.78' : '1'}
      />
      <path
        d='M23.8237 65.1489C24.457 65.8486 25.7776 65.6917 26.0933 65.0335V48.1817L23.8237 48.6281V65.1489Z'
        fill='#303030'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M15.9478 0.5C16.0187 0.5 53.7649 5.24857 53.7649 5.24857L53.7239 28.3853L16.4991 21.5311L15.9478 0.5Z'
        fill='#272425'
        stroke='white'
        strokeWidth='0.0989473'
      />
      <path
        d='M17.2549 1.44433L17.2706 1.44633L17.5752 1.48588C17.8407 1.52053 18.2283 1.57136 18.7204 1.63606C19.7047 1.76545 21.1071 1.9503 22.7882 2.17212C26.1503 2.61577 30.6269 3.20729 35.1015 3.79882C39.576 4.39035 44.0485 4.98187 47.4023 5.42552L51.4545 5.96159L52.5896 6.11178L52.8887 6.15135L52.9206 6.15557V26.9447L17.5045 20.8825L17.2549 1.44433Z'
        fill='#00BBE6'
        stroke='#00BBE6'
        strokeWidth='0.162729'
      />
      <path
        d='M23.9269 7.20624C22.2007 6.63082 18.4068 9.04103 17.4478 10.0001L17.4535 20.7838L53.002 27.0412V15.8905C51.8032 17.0893 49.9645 17.276 47.6628 17.276C44.7858 17.276 43.3472 15.1182 41.1894 12.9604C39.0316 10.8026 34.716 13.6797 31.1196 12.9604C27.5233 12.2411 26.0847 7.92551 23.9269 7.20624Z'
        fill='white'
        fillOpacity='0.38'
      />
      <path
        d='M54.5207 28.5692L68.0635 34.2546L68.1338 10.559L54.4451 5.492L54.5207 28.5692Z'
        fill='#272425'
      />
      <path
        d='M68.0737 10.5259L68.4623 10.1703L68.5594 33.8517L68.1036 34.3037L68.0737 10.5259Z'
        fill='#B1B1B1'
      />
      <path
        d='M14.9371 21.5517L1.41309 24.2752L1.41495 1.31633L14.812 0.0680542L14.9371 21.5517Z'
        fill='#272425'
      />
      <path
        d='M13.9503 20.6809L2.38654 23.1183L2.2998 2.02809L13.9503 1.00018V20.6809Z'
        fill='#00BBE6'
      />
      <path
        d='M8.85964 16.6446C10.4911 16.0803 12.9355 14.247 13.8945 13.0482V20.7177L2.38621 23.118C2.38621 20.3351 2.31733 14.9507 2.30257 14.2386C2.30073 14.2238 2.2998 14.2098 2.2998 14.1966C2.2998 14.135 2.3008 14.1531 2.30257 14.2386C2.40073 15.0266 5.10543 17.943 8.85964 16.6446Z'
        fill='white'
        fillOpacity='0.65'
      />
      <path
        d='M1.41474 1.31626L0.947754 1.24438V24.1295L1.41288 24.2752L1.41474 1.31626Z'
        fill='#888888'
      />
      <path
        d='M0.947754 1.2445L14.0496 0L14.8118 0.068088L1.41474 1.31637L0.947754 1.2445Z'
        fill='#888888'
      />
      <path
        d='M15.9478 0.499939L17.7141 0.166443L54.0179 5.03852L53.7564 5.24846L15.9478 0.499939Z'
        fill='#828282'
      />
      <path
        d='M53.7572 5.24815L54.0187 5.03821L53.9645 28.069L53.7161 28.3848L53.7572 5.24815Z'
        fill='#929292'
      />
      <path
        d='M55.1595 6.51569C55.2261 6.51569 67.4634 11.0018 67.4634 11.0018L67.5676 33.1626L55.3629 28.0773L55.1595 6.51569Z'
        fill='#00BBE6'
      />
      <path
        d='M54.4451 5.49223L55.1678 5.28882L68.4804 10.1707L68.0736 10.5775L54.4451 5.49223Z'
        fill='#7F7F7F'
      />
      <path
        d='M31.6567 17.8939C28.0604 17.1747 26.6218 12.859 24.464 12.1397C22.1217 11.359 19.9659 14.0661 17.9478 14.9175C17.9478 16.8355 17.9906 21.3779 17.9906 21.3779C17.9906 21.3779 41.8135 25.5782 53.5616 27.4963L53.4765 18.47C52.2777 19.6688 50.5016 22.2096 48.2 22.2096C45.3229 22.2096 43.8843 20.0517 41.7265 17.8939C39.5687 15.7361 35.2531 18.6132 31.6567 17.8939Z'
        fill='white'
        transform='matrix(1 0 0 1 -0.5662909746170044 -0.47032039760978606)'
      />
      <path
        d='M61.066 24.0984C57.4896 23.4172 56.3197 18.9958 55.3607 17.7971V28.1006L67.6228 33.2098C67.6228 30.093 67.561 21.3913 67.561 21.9667C67.561 22.686 64.521 24.7565 61.066 24.0984Z'
        fill='white'
        fillOpacity='0.65'
      />
      <path
        d='M17.0754 65.2947V66.922C17.0754 66.922 18.7399 69.9731 25.1539 69.9731C31.5678 69.9731 34.0106 68.1592 34.1659 67.7356C34.3212 67.312 34.3074 65.7015 34.3074 65.7015C34.2278 67.0762 31.663 68.1424 25.5607 68.5493C23.3285 68.4643 21.7036 68.1658 19.8652 67.5322C17.8576 66.8891 17.5906 66.374 17.0754 65.2947Z'
        fill={light ? '#2F2F2F' : 'white'}
        fillOpacity={light ? '1' : '0.6'}
      />
      <path
        d='M 15.9478 0.5 C 16.0187 0.5 53.7649 5.24857 53.7649 5.24857 L 53.7239 28.3853 L 16.4991 21.5311 L 15.9478 0.5 Z'
        fill='#272425'
        transform='matrix(0.9849949479103088 0.172588512301445 -0.010863035917282104 0.06199735030531883 0.7209543585777283 17.311575937259825)'
      ></path>
      <path
        d='M 54.5207 28.5692 L 68.0635 34.2546 L 68.1338 10.559 L 54.4451 5.492 L 54.5207 28.5692 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9870632290840149 0.1603451520204544 -0.10456253588199615 0.6436715126037598 2.1513645648956303 16.62677574157715)'
        id='path1325'
      ></path>
      <path
        d='M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9830948114395142 -0.15487055480480194 0.09480227530002594 0.4248824119567871 -0.1279947698059838 23.901689076418002)'
        id='path5889'
      ></path>
      <path
        d='M 15.9478 0.5 C 16.0187 0.5 53.7649 5.24857 53.7649 5.24857 L 53.7239 28.3853 L 16.4991 21.5311 L 15.9478 0.5 Z'
        fill={light ? '#e8f9fe' : '#2f2f2f'}
        transform='matrix(0.9838485717773438 0.16133137047290802 -0.03668415546417236 0.1541094332933426 1.5079946517944345 18.646788644779356)'
        id='path4470'
      ></path>
      <path
        d='M 14.9371 21.5517 L 1.41309 24.2752 L 1.41495 1.31633 L 14.812 0.0680542 L 14.9371 21.5517 Z'
        fill='#272425'
        transform='matrix(-0.9571131467819214 -0.3885623812675476 -0.01319006085395813 0.04113972187042236 69.30864479541214 33.61340949535934)'
        id='path6134'
      ></path>
    </svg>
  );
};

export default Museums;
