import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './NavMenu.module.scss';
import Toggle from '../Toggle/Toggle';
import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const NavMenu = () => {
  const { theme, toggle, light } = useContext(ThemeContext);

  const [links, setLinks] = useState([
    { title: 'О компании', link: '/about' },
    { title: 'Портфолио', link: '/portfolio' },
    { title: 'Услуги', link: '/services' },
    { title: 'Контакты', link: '/contacts' },
  ]);

  return (
    <nav>
      <ul className={styles.navList}>
        {links.map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.link}
              className={styles.link}
              style={({ isActive }) =>
                isActive
                  ? {
                      borderBottom: '2px solid #00B8EA',
                      filter: 'drop-shadow(0px 0px 2px #fff)',
                    }
                  : {}
              }
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;
