
import styles from './Astafiev.module.scss';
import desctopTicket from './astafiev/desktop_ticket.png'
import mobileTicket1 from './astafiev/mobile_ticket_1.png'
import mobileTicket2 from './astafiev/mobile_ticket_2.png'

export const Ticket = () => {
    const isMobile = window.innerWidth < 550

    const MobileTicket = (
        <div className={styles.ticketGrid}>
            <div>
                <img src={mobileTicket1} />
            </div>
            <div>
                <img src={mobileTicket2} />
            </div>
        </div>
    )

    const DesctopTicket = (
        <div className={styles.desktopTicket}>
            <img src={desctopTicket} />
        </div>
    )
    
    return isMobile ? MobileTicket : DesctopTicket
}