import React, { useContext, useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

// import { ReactComponent as Logo } from '../../../assets/image/logo.svg';
import Logo from '../../../assets/image/logo.png';

import { ReactComponent as Phone } from '../../../assets/image/header-phone.svg';
import { ReactComponent as Burger } from '../../../assets/image/header-burger.svg';
import { ReactComponent as CloseMenu } from '../../../assets/image/close-burger.svg';
import Container from '../Container/Container';
import NavMenu from '../NavMenu/NavMenu';

import styles from './Header.module.scss';
import NavMenuMobile from '../NavMenu/NavMenuMobile';
import SocialLink from '../SocialLink/SocialLinkMobile';
// import Logo from '../SVGs/Logo';
import { StateContext } from '../../../context/StateContext';

const Header = () => {
  const [isShowMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const { pathname } = useLocation();
  const { setIsSnow } = useContext(StateContext);

  useEffect(() => {
    if (window.innerWidth > 1000) setIsMobile(false);
    if (window.innerWidth <= 1000) setIsMobile(true);
  }, []);

  useEffect(() => {
    if (isShowMenu) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100%';
    } else {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').style.height = 'auto';
    }
  }, [isShowMenu]);

  return (
    <div id='header' className={styles.header}>
      <Container width={100}>
        <div className={styles.headerContent}>
          {/* <Link style={{ position: 'relative', top: '-4px' }} to='/'> */}
          <Link to='/'>
            {/* <Logo /> */}
            <img className={styles.logo} src={Logo} alt='Logo' />
            {/* <p className={styles.textFooter}>работаем с 2014г</p> */}
          </Link>
          {!isMobile ? (
            <NavMenu />
          ) : (
            <>
              <div className={styles.mobileControl}>
                <a style={{ zIndex: 100 }} href='tel:+7 913 839 6909'>
                  <Phone />
                </a>
                {!isShowMenu ? (
                  <Burger
                    onClick={() => setShowMenu(true)}
                    style={{ zIndex: 100 }}
                  />
                ) : (
                  <CloseMenu
                    onClick={() => setShowMenu(false)}
                    style={{ zIndex: 100 }}
                  />
                )}
              </div>
              <NavMenuMobile
                isShowMenu={isShowMenu}
                setShowMenu={setShowMenu}
              />
            </>
          )}
          {!isMobile && <SocialLink />}
        </div>
      </Container>
    </div>
  );
};

export default Header;
