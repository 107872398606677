import styles from '../Astafiev.module.scss';

import rewindNext from '../astafiev/REWIND_NEXT.png'
import rewindBack from '../astafiev/REWIND_BACK.png'
import pause from '../astafiev/PAUSE.png'
import play from '../astafiev/PLAY.png'


export const AudioButtons = ({
    isPlaying, setIsPlaying, audioRef, setCurrentProgress, currentProgress
}) => {

    const togglePlayPause = () => {
        if (isPlaying){
            console.log('PLAY')
            audioRef.current.pause()
            setIsPlaying(false)
        } else {
            console.log('PAUSE')
            audioRef.current.play()
            setIsPlaying(true)
        }
    }

    return  <div className={styles.audioPlayerButtons}>
        <div className={styles.rewind} onClick={() => audioRef.current.currentTime = currentProgress - 30}>
            <img src={rewindBack} />
        </div>

            { isPlaying ? (
                <div className={styles.playPause} onClick={() => togglePlayPause()}>
                    <img src={pause} />
                </div>
            ) : (
                <div className={styles.playPause} onClick={() => togglePlayPause()}>
                    <img src={play} />
                </div>
            )}

        <div className={styles.rewind} onClick={() => audioRef.current.currentTime = currentProgress + 30}>
            <img src={rewindNext} />
        </div>
    </div>
}