import React, { useEffect, useState } from 'react';
import PageTemplate from '../../PageTemplate/PageTemplate';
import Container from '../../elements/Container/Container';
import { Helmet } from 'react-helmet';

import styles from './Services.module.scss';

import arrow from '../../../assets/image/right-arrow.svg';
import arrowLight from '../../../assets/image/right-arrowLight.svg';
import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';
import { Link, useLocation } from 'react-router-dom';
import { getServices } from '../../../API/request/getServices';

const Services = () => {
  const { theme, toggle, light } = useContext(ThemeContext);
  const [services, setServices] = useState([]);

  const location = useLocation();

  useEffect(() => {
    getServices().then((data) =>
      setServices(data?.results.sort((a, b) => a.id - b.id))
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Итео - Услуги</title>
        <meta name='description' content='Какие услуги мы предоставляем.' />
      </Helmet>
      <PageTemplate>
        <Container width={90}>
          {services.length > 0 && (
            <section className={styles.services}>
              <div className={styles.text}>
                <div className={styles.container}>
                  <div className={styles.textService}>
                    <p className={styles.title}>Разработка</p>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/1'}
                      state={{ prevPath: location.pathname }}
                    >
                      <span>{services[0].title}</span>
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/2'}
                      state={{ prevPath: location.pathname }}
                    >
                      <span>{services[1].title}</span>
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/3'}
                      state={{ prevPath: location.pathname }}
                    >
                      <span>{services[2].title}</span>
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/4'}
                      state={{ prevPath: location.pathname }}
                    >
                      <span>{services[3].title}</span>
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    Фото-видео съемка
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    Создание видеороликов
                  </div>
                </div>

                <div className={styles.container}>
                  <div className={styles.textService}>
                    <p className={styles.title}>Оборудование</p>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/5'}
                      state={{ prevPath: location.pathname }}
                    >
                      {services[4].title}
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/6'}
                      state={{ prevPath: location.pathname }}
                    >
                      {services[5].title}
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    Оптовая торговля интерактивным оборудованием
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    Оформление выставочных пространств, музейных экспозиций
                  </div>
                </div>

                <div className={styles.container}>
                  <div className={styles.textService}>
                    <p className={styles.title}>Работа с сайтами</p>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/7'}
                      state={{ prevPath: location.pathname }}
                    >
                      {services[6].title}
                    </Link>
                  </div>
                  <div className={styles.serviceName}>
                    <img src={light ? arrowLight : arrow} alt='' />
                    <Link
                      to={'/service/8'}
                      state={{ prevPath: location.pathname }}
                    >
                      {services[7].title}
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Container>
      </PageTemplate>
    </>
  );
};

export default Services;
