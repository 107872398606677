import styles from './Astafiev.module.scss';
import header1 from './astafiev/header_1.png'
import header2 from './astafiev/header_2.png'
import header3 from './astafiev/header_3.png'
import header4 from './astafiev/header_4.png'

export const Header = ({content}) => {
    const header = {
        'CR': header1,
        'PIU': header2,
        'VO': header3,
        'KSRG': header4,
    }

    return <div className={styles.header}>
        <img src={header[content]} />
    </div>
}