
import styles from './Astafiev.module.scss';
import bookCover1 from './astafiev/book_cover_1.png'
import bookCover2 from './astafiev/book_cover_2.png'
import bookCover3 from './astafiev/book_cover_3.png'
import bookCover4 from './astafiev/book_cover_4.png'


export const BookCover = ({content}) => {
    const cover = {
        'CR': bookCover1,
        'PIU': bookCover2,
        'VO': bookCover3,
        'KSRG': bookCover4,
    }

    return <div className={styles.bookCover}>
        <img src={cover[content]} />
    </div>
}