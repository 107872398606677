import classNames from 'classnames';
import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTemplate from '../../PageTemplate/PageTemplate';
import Container from '../../elements/Container/Container';
import ReactInputMask from 'react-input-mask';

import Arenda from '../../elements/SVGs/Arenda';
import ArendaA from '../../elements/SVGs/ArendaA';
import Sites from '../../elements/SVGs/Sites';
import SitesA from '../../elements/SVGs/SitesA';
import Museums from '../../elements/SVGs/Museums';
import MuseumsA from '../../elements/SVGs/MuseumsA';
import MobileApps from '../../elements/SVGs/MobileApps';
import MobileAppsA from '../../elements/SVGs/MobileAppsA';
import VR from '../../elements/SVGs/VR';
import VRA from '../../elements/SVGs/VRA';

import styles from './Service.module.scss';

import { ThemeContext } from '../../../context/theme-context';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { sendCalculate } from '../../../API/request/sendCalculate';
import { getService } from '../../../API/request/getService';
import { Helmet } from 'react-helmet';
import toCapitalize from '../../../utils/toCapitalize';

const Service = () => {
  const { theme, toggle, light } = useContext(ThemeContext);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isFocusName, setIsFocusName] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isFocusEmail, setIsFocusEmail] = useState(true);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [isFocusPhone, setIsFocusPhone] = useState(true);

  const [text, setText] = useState('');

  const [isUpdateSites, setIsUpdateSites] = useState(false);
  const [isUpdateMuseums, setIsUpdateMuseums] = useState(false);
  const [isUpdateArenda, setIsUpdateArenda] = useState(false);
  const [isUpdateVR, setIsUpdateVR] = useState(false);
  const [isUpdateMobileApps, setIsUpdateMobileApps] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentService, setCurrentService] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const { state } = useLocation();

  useEffect(() => {
    getService(id).then((data) => setCurrentService(data));
  }, [id]);

  useEffect(() => {
    if (isOpenModal) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100%';
      window.scrollTo(0, 0);
    } else {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').style.height = 'auto';
    }
  }, [isOpenModal]);

  const openModal = () => {
    window.scrollTo(0, 0);
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('body').style.height = '100%';
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setSuccess(false);
    setName('');
    setEmail('');
    setPhone('');
    setText('');
    setIsFocusName(true);
    setIsFocusEmail(true);
    setIsFocusPhone(true);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
  };

  const send = () => {
    sendCalculate({ name, email, phone, text });
    setSuccess(true);
  };

  useEffect(() => {
    if (!isFocusName) {
      if (!name.trim()) {
        setNameError(true);
        setName('');
      } else {
        setNameError(false);
      }
    }
    if (!isFocusEmail) {
      if (!email.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    if (!isFocusPhone) {
      if (phone.charAt(phone.length - 1) == '_') {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
  }, [name, email, phone, isFocusName, isFocusEmail, isFocusPhone]);

  if (!currentService) return;
  return (
    <>
      <Helmet>
        <title>Итео - {toCapitalize(currentService.title)}</title>
        <meta
          name='description'
          content={
            id == 1 && 'Разрабатываем концепцию и ПО для выставок и музеев'
          }
        />
      </Helmet>
      <PageTemplate>
        <Container width={100}>
          <section className={styles.service}>
            <div className={styles.container}>
              <img src={currentService?.image} />
              {width >= 1000 && (
                <p className={styles.imageName}>
                  {currentService?.image_description}
                </p>
              )}
            </div>
            <div className={styles.containerText}>
              <h2>{currentService?.title}</h2>
              <div className={styles.text}>
                <p
                  className={styles.textParagraph}
                  dangerouslySetInnerHTML={{
                    __html: currentService?.description,
                  }}
                ></p>
              </div>
            </div>
            <button onClick={openModal} className={styles.buttonPrice}>
              Рассчитать стоимость
            </button>
            <div
              className={classNames(styles.skills, {
                [styles.skillsMobile]: width < 980,
              })}
            >
              {/* {currentService?.skills?.arenda && (
              <Link to='/service/5' state={{ prevPath: state.prevPath }}>
                <div className={styles.item}>
                  {isUpdateArenda ? (
                    <ArendaA setIsUpdateArenda={setIsUpdateArenda} />
                  ) : (
                    <Arenda setIsUpdateArenda={setIsUpdateArenda} />
                  )}
                  <span
                    className={classNames(styles.description, {
                      [styles.lightDescription]: light,
                    })}
                  >
                    аренда оборудования
                  </span>
                </div>
              </Link>
            )}
            {currentService?.skills?.vr && (
              <Link
                to={id == 2 ? '/service/3' : '/service/2'}
                state={{ prevPath: state.prevPath }}
              >
                <div className={styles.item}>
                  {isUpdateVR ? (
                    <VRA setIsUpdateVR={setIsUpdateVR} />
                  ) : (
                    <VR setIsUpdateVR={setIsUpdateVR} />
                  )}
                  <span
                    className={classNames(styles.description, {
                      [styles.lightDescription]: light,
                    })}
                  >
                    {id == 2 ? '3D-моделирование' : 'VR / AR'}
                  </span>
                </div>
              </Link>
            )}
            {currentService?.skills?.museums && (
              <Link
                to={id == 1 ? '/service/6' : '/service/1'}
                state={{ prevPath: state.prevPath }}
              >
                <div className={styles.item}>
                  {isUpdateMuseums ? (
                    <MuseumsA setIsUpdateMuseums={setIsUpdateMuseums} />
                  ) : (
                    <Museums setIsUpdateMuseums={setIsUpdateMuseums} />
                  )}
                  <span
                    className={classNames(styles.description, {
                      [styles.lightDescription]: light,
                    })}
                  >
                    {id == 1 ? 'подбор оборудования' : 'музеи / выставки'}
                  </span>
                </div>
              </Link>
            )}
            {currentService?.skills?.sites && (
              <Link to={'/service/7'} state={{ prevPath: state.prevPath }}>
                <div className={styles.item}>
                  {isUpdateSites ? (
                    <SitesA setIsUpdateSites={setIsUpdateSites} />
                  ) : (
                    <Sites setIsUpdateSites={setIsUpdateSites} />
                  )}
                  <span
                    className={classNames(styles.description, {
                      [styles.lightDescription]: light,
                    })}
                  >
                    сайты
                  </span>
                </div>
              </Link>
            )}
            {currentService?.skills?.mobileApp && (
              <Link to={'/service/4'} state={{ prevPath: state.prevPath }}>
                <div className={styles.item}>
                  {isUpdateMobileApps ? (
                    <MobileAppsA
                      setIsUpdateMobileApps={setIsUpdateMobileApps}
                    />
                  ) : (
                    <MobileApps setIsUpdateMobileApps={setIsUpdateMobileApps} />
                  )}
                  <span
                    className={classNames(styles.description, {
                      [styles.lightDescription]: light,
                    })}
                  >
                    мобильные приложения
                  </span>
                </div>
              </Link>
            )} */}
              <button
                onClick={() => navigate(state.prevPath)}
                className={classNames(styles.backButton, {
                  [styles.backButtonMobile]: width < 980,
                })}
              >
                назад
              </button>
            </div>
          </section>
        </Container>
        {isOpenModal && (
          <div className={styles.modal} onClick={() => closeModal()}>
            <div
              onClick={(e) => e.stopPropagation()}
              className={styles.content}
            >
              <button onClick={closeModal} className={styles.closeModal}>
                закрыть
              </button>
              {!success ? (
                <div className={styles.form}>
                  <p className={styles.title}>заказать расчет</p>
                  <p className={styles.subTitle}>
                    оставьте контакты, и мы свяжемся с вами
                  </p>
                  <input
                    onFocus={() => setIsFocusName(true)}
                    onBlur={() => setIsFocusName(false)}
                    className={classNames({ [styles.inputError]: nameError })}
                    required
                    type='text'
                    placeholder='имя*'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <input
                    onFocus={() => setIsFocusEmail(true)}
                    onBlur={() => setIsFocusEmail(false)}
                    className={classNames({ [styles.inputError]: emailError })}
                    required
                    type='email'
                    placeholder='email*'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <ReactInputMask
                    onFocus={() => setIsFocusPhone(true)}
                    onBlur={() => setIsFocusPhone(false)}
                    className={classNames({ [styles.inputError]: phoneError })}
                    type='tel'
                    mask='+7\ (999) 999-99-99'
                    placeholder='телефон*'
                    maskChar='_'
                    required={true}
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  <input
                    required
                    type='text'
                    placeholder='кратко опишите свой проект*'
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    t
                  />
                  <p style={{ marginBottom: '10px' }}>
                    *- поля обязательные для заполнения
                  </p>
                  <p>
                    нажимая отправить, вы соглашаетесь с{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIsOpenModalConfirm(true)}
                    >
                      обработкой ваших персональных данных
                    </span>
                  </p>
                  <button
                    onClick={send}
                    disabled={
                      !name ||
                      phone.charAt(phone.length - 1) == '_' ||
                      !phone ||
                      !text
                    }
                    className={styles.send}
                  >
                    отправить
                  </button>
                </div>
              ) : (
                <p className={styles.success}>Успешно!</p>
              )}
            </div>
          </div>
        )}
        {isOpenModalConfirm && (
          <div className={styles.modal}>
            <div className={styles.content}>
              <button
                onClick={() => setIsOpenModalConfirm(false)}
                className={styles.closeModal}
              >
                закрыть
              </button>
              <div className={styles.textConfirm}>
                <p>
                  Настоящим я, далее – «Субъект Персональных Данных», во
                  исполнение требований Федерального закона от 27.07.2006 г. №
                  152-ФЗ «О персональных данных» (с изменениями и дополнениями)
                  свободно, своей волей и в своем интересе даю свое согласие
                  организации ООО «ИТЕО» (далее – Компания, юридический адрес:
                  660017, Красноярский край, город Красноярск, ул. Красной
                  Армии, д. 10) на обработку своих персональных данных,
                  указанных путем заполнения веб-формы на сайте iteo.pro и его
                  поддоменов *.iteo.pro (далее – Сайт), направляемой
                  (заполненной) с использованием Сайта.
                </p>
                <p>
                  Под персональными данными я понимаю любую информацию,
                  относящуюся ко мне как к Субъекту Персональных Данных, в том
                  числе мои фамилию, имя, отчество, адрес, образование,
                  профессию, контактные данные (телефон, факс, электронная
                  почта, почтовый адрес), фотографии, иную другую информацию.
                  Под обработкой персональных данных я понимаю сбор,
                  систематизацию, накопление, уточнение, обновление, изменение,
                  использование, распространение, передачу, в том числе
                  трансграничную, обезличивание, блокирование, уничтожение,
                  бессрочное хранение), и любые другие действия (операции) с
                  персональными данными.
                </p>
                <p>
                  Обработка персональных данных Субъекта Персональных Данных
                  осуществляется исключительно в целях регистрации Субъекта
                  Персональных Данных в базе данных Компании с последующим
                  направлением Субъекту Персональных Данных почтовых сообщений и
                  смс-уведомлений, в том числе рекламного содержания, от
                  Компании, его аффилированных лиц и/или субподрядчиков,
                  информационных и новостных рассылок, приглашений на
                  мероприятия Компании и другой информации рекламно-новостного
                  содержания, а также с целью подтверждения личности Субъекта
                  Персональных Данных при посещении мероприятий Компании.
                </p>
                <p>
                  Датой выдачи согласия на обработку персональных данных
                  Субъекта Персональных Данных является дата отправки веб-формы
                  с Сайта Компании.
                </p>
                <p>
                  Обработка персональных данных Субъекта Персональных Данных
                  может осуществляться с помощью средств автоматизации и/или без
                  использования средств автоматизации в соответствии с
                  действующим законодательством РФ и внутренними положениями
                  Компании.
                </p>
                <p>
                  Компания принимает необходимые правовые, организационные и
                  технические меры или обеспечивает их принятие для защиты
                  персональных данных от неправомерного или случайного доступа к
                  ним, уничтожения, изменения, блокирования, копирования,
                  предоставления, распространения персональных данных, а также
                  от иных неправомерных действий в отношении персональных
                  данных, а также принимает на себя обязательство сохранения
                  конфиденциальности персональных данных Субъекта Персональных
                  Данных. Компания вправе привлекать для обработки персональных
                  данных Субъекта Персональных Данных субподрядчиков, а также
                  вправе передавать персональные данные для обработки своим
                  аффилированным лицам, обеспечивая при этом принятие такими
                  субподрядчиками и аффилированными лицами соответствующих
                  обязательств в части конфиденциальности персональных данных.
                </p>
                <p>Я ознакомлен(а), что:</p>
                <ul>
                  <li>
                    настоящее согласие на обработку моих персональных данных,
                    указанных при регистрации на Сайте Компании, направляемых
                    (заполненных) с использованием Cайта, действует в течение 20
                    (двадцати) лет с использования формы на Cайте Компании;
                  </li>
                  <li>
                    согласие может быть отозвано мною на основании письменного
                    заявления в произвольной форме;
                  </li>
                  <li>
                    предоставление персональных данных третьих лиц без их
                    согласия влечет ответственность в соответствии с действующим
                    законодательством Российской Федерации.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </PageTemplate>
    </>
  );
};

export default Service;
