import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './ProjectSlider.module.scss';

const ProjectSlider = ({ photos, openModal }) => {
  const [mainImgHeight, setMainImgHeigth] = useState(291);
  const [mainImgWidth, setMainImgWidth] = useState(90);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const mainImg = useRef();

  const settingsFirst = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  const settingsSecond = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    focusOnSelect: true,
    initialSlide: 0,
  };

  useEffect(() => {
    if (!mainImg) return;
    if (!mainImg.current) return;
    setMainImgWidth(mainImg.current.clientWidth);
    setMainImgHeigth(mainImg.current.clientWidth * 0.57);
  });

  window.addEventListener('resize', () => {
    setMainImgWidth(mainImg.current.clientWidth);
    setMainImgHeigth(mainImg.current.clientWidth * 0.57);
  });

  return (
    <>
      <Slider
        {...settingsFirst}
        ref={(slider1) => setNav1(slider1)}
        asNavFor={nav2}
      >
        {photos.map((item, index) => (
          <div key={index} onClick={() => openModal(item.image)}>
            <div
              className={styles.item}
              ref={mainImg}
              style={{
                height: mainImgHeight,
                backgroundImage: `url(${item.image})`,
              }}
            ></div>
          </div>
        ))}
      </Slider>
      <Slider
        {...settingsSecond}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
      >
        {photos.map((item, index) => (
          <div key={index}>
            <div
              className={styles.subItem}
              style={{
                width: mainImgWidth / 3 - 5,
                height: (mainImgWidth / 4 - 5) * 0.6,
                marginLeft: 2,
                backgroundImage: `url(${item.image})`,
                backgroundPosition: 'center',
              }}
            ></div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ProjectSlider;
