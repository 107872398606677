import React, { useContext, useEffect, useRef, useState } from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';

import styles from './Portfolio.module.scss';
import { Link } from 'react-router-dom';
import { StateContext } from '../../../context/StateContext';
import { getProjects } from '../../../API/request/getProjects';
import { Helmet } from 'react-helmet';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [tmpProjects, setTmpProjects] = useState([]);
  const { portfolioScroll, setPortfolioScroll } = useContext(StateContext);

  useEffect(() => {
    getProjects().then((data) => {
      const projects = data.results;
      const element = projects[10];
      projects.splice(10, 1);
      projects.splice(0, 0, element);
      setProjects(projects);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, portfolioScroll);
  });

  useEffect(() => {
    const splitArray = (arr, length) => {
      const tmp = [];

      for (let i = 0; i < arr.length; i += length) {
        tmp.push(arr.slice(i, i + length));
      }

      return tmp;
    };
    let tmp = [];
    let tmp2 = [];
    let arrayTmp = [...projects];
    tmp = arrayTmp.splice(0, 5);
    arrayTmp = [...projects];
    tmp2 = splitArray(arrayTmp.slice(5), 6);
    setTmpProjects([[...tmp], [...tmp2]]);
  }, [projects]);
  return (
    <>
      <Helmet>
        <title>Итео - Портфолио</title>
        <meta name='description' content='30+ проектов. Музейные инсталляции, сайты, VR...' />
      </Helmet>
      <PageTemplate>
        <Container width={80}>
          <div className={styles.wrapper}>
            <section className={styles.portfolio}>
              {tmpProjects.length > 0 &&
                tmpProjects[0].map((item, index) => (
                  <div
                    onClick={() => {
                      setPortfolioScroll(window.scrollY);
                    }}
                    className={styles.item}
                    key={index}
                  >
                    <Link to={'/project/' + item.id}>
                      <div className={styles.imageContainer}>
                        <img src={item.project_cover_img} />
                        <div className={styles.title}>
                          <span className={styles.titleText}>{item.title}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </section>
            {tmpProjects.length > 0 &&
              tmpProjects[1].map((item, index) => (
                <section key={index} className={styles.portfolioSecond}>
                  {item.map((item, index) => (
                    <div
                      onClick={() => {
                        setPortfolioScroll(window.scrollY);
                      }}
                      key={index}
                      className={styles.item}
                    >
                      <Link
                        style={{ height: '100%', width: '100%' }}
                        to={'/project/' + item.id}
                      >
                        <div className={styles.imageContainer}>
                          <img src={item.project_cover_img} />
                          <div className={styles.title}>
                            <span className={styles.titleText}>
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </section>
              ))}
          </div>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Portfolio;
