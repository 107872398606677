import React from 'react';
import Footer from '../elements/Footer/Footer';
import Header from '../elements/Header/Header';
import { useContext } from 'react';
import { ThemeContext } from '../../context/theme-context';
import useWindowWidth from '../../hooks/useWindowWidth';

const PageTemplate = ({ children }) => {
  const { theme, toggle, light } = useContext(ThemeContext);
  const width = useWindowWidth();
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: light ? '#e8f9fe' : '#2F2F2F',
        color: light ? '#2F2F2F' : 'white',
        overflow: 'hidden',
      }}
    >
      <p style={{ visibility: 'hidden', height: 0 }}>
        Интерактивные инсталляции для музеев и выставок.
      </p>
      <div style={{ margin: '0 auto', width: '100%' }}>
        <Header />
        <div
          style={{
            flex: '1 1 auto',
            paddingTop: width > 1150 ? '62px' : '92px',
          }}
        >
          {children}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default PageTemplate;
