import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const MobileAppsA = ({ setIsUpdateMobileApps }) => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <svg
      onMouseEnter={() => setIsUpdateMobileApps(true)}
      onMouseLeave={() => setIsUpdateMobileApps(false)}
      width='65'
      height='76'
      viewBox='0 0 65 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id='svg1775'
      preserveAspectRatio='xMidYMid'
    >
      <path
        d='M 59.0186 25.9852 C 59.8254 26.8035 59.8254 22.7294 57.2522 21.4428 L 57.4667 24.5104 L 57.6812 27.0179 L 59.0186 25.9852 Z'
        fill='#7F7F7F'
        id='path4961'
      ></path>
      <path
        d='M 5.36076 51.0339 C 4.19464 53.3661 4.99214 47.2343 5.57519 47.8175 L 6.91266 49.559 L 5.74658 53.0573 L 5.36076 51.0339 Z'
        fill='#7F7F7F'
        id='path5106'
      ></path>
      <path
        d='M 22.5074 67.0964 L 5.89832 53.5429 C 4.56484 52.4547 4.42561 50.4187 5.52093 49.0764 L 37.4487 9.99255 C 37.9732 9.34979 38.7306 8.94094 39.5542 8.85593 C 40.3779 8.77092 41.2004 9.01672 41.8407 9.53926 L 58.448 23.0913 C 58.765 23.35 59.0278 23.6689 59.2214 24.0298 C 59.4149 24.3906 59.5354 24.7863 59.5759 25.1943 C 59.6165 25.6023 59.5763 26.0146 59.4577 26.4076 C 59.3391 26.8007 59.1444 27.1668 58.8847 27.485 L 26.9637 66.5682 C 26.4366 67.2235 25.6739 67.6462 24.8403 67.745 C 24.0066 67.8438 23.1688 67.6109 22.5074 67.0964 Z'
        fill='#7F7F7F'
        id='path61'
        stroke='#00B8EA'
        strokeWidth='0.823396'
      ></path>
      <g
        clipPath='url(#clip0_998_23388)'
        id='g3564'
        transform='matrix(1 0 0 1 0.015625 0.015625)'
      >
        <path
          d='M 22.5887 64.0156 L 6.41068 50.8138 C 5.1118 49.7539 4.9705 47.7777 6.03077 46.4784 L 36.9371 8.64505 C 37.4448 8.02286 38.1795 7.62834 38.9796 7.54828 C 39.7796 7.46822 40.5795 7.70918 41.2032 8.21816 L 57.3794 21.4185 C 57.6883 21.6705 57.9446 21.9807 58.1337 22.3314 C 58.3228 22.6822 58.441 23.0665 58.4817 23.4626 C 58.5223 23.8586 58.4845 24.2586 58.3704 24.6397 C 58.2563 25.0207 58.0682 25.3754 57.8168 25.6835 L 26.917 63.5162 C 26.4067 64.1505 25.667 64.5584 24.8573 64.6518 C 24.0476 64.7453 23.2328 64.5168 22.5887 64.0156 Z'
          fill='#272425'
          id='path3868'
          stroke='#00B8EA'
          strokeWidth='0.823396'
        ></path>
        <g opacity='0.6' id='g5018'>
          <path
            d='M -0.021124500781297684 11.469409942626953 L 18.66447577998042 11.469409942626953 L 18.66447577998042 19.885990142822266 L -0.021124500781297684 19.885990142822266 Z'
            transform='matrix(0.7747734189033508 0.6322388052940369 -0.6322388052940369 0.7747734189033508 14.058600425720215 42.45920181274414)'
            fill='white'
            id='rect816'
            x='-0.02112458308508991'
            y='11.469409946237313'
          ></path>
          <path
            d='M 0 0 L 18.68560028076172 0 L 18.68560028076172 8.416580200195312 L 0 8.416580200195312 Z'
            transform='translate(14.0586 42.4592) rotate(39.2155)'
            fill='white'
            id='rect505'
          ></path>
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;7.15 -8.69'
            calcMode='spline'
            keyTimes='0;1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform2662'
          ></animateTransform>
        </g>
        <path
          d='M 25.2926 62.6802 L 38.8785 46.0315 L 35.4854 43.2626 L 30.2073 38.9555 L 24.9292 34.6484 L 20.7821 31.2642 L 7.19623 47.9129 C 6.87 48.3127 6.92963 48.9012 7.3294 49.2275 L 23.9781 62.8133 C 24.3779 63.1396 24.9664 63.0799 25.2926 62.6802 Z'
          fill='white'
          fillOpacity='0.12'
          id='path4486'
        ></path>
        <g opacity='0.6' id='g987'>
          <path
            d='M 0 0 L 18.68560028076172 0 L 18.68560028076172 8.046110153198242 L 0 8.046110153198242 Z'
            transform='translate(21.0817 33.853) rotate(39.2155)'
            fill='white'
            id='rect8958'
          ></path>
          <animateTransform
            attributeName='transform'
            attributeType='auto'
            type='translate'
            values='0 0;7.77 -8.73'
            calcMode='spline'
            keyTimes='0;1'
            keySplines='0 0 1 1'
            dur='0.3s'
            begin='0s'
            repeatCount='100'
            additive='sum'
            accumulate='none'
            fill='freeze'
            id='animateTransform6281'
          ></animateTransform>
        </g>
        <path
          d='m 45.51622223854065 13.13129997253418 a 0.4838240146636963 0.48353201150894165 0 0 1 -0.4838240146636963 0.48353201150894165 0.4838240146636963 0.48353201150894165 0 0 1 -0.4838240146636963 -0.48353201150894165 0.4838240146636963 0.48353201150894165 0 0 1 0.4838240146636963 -0.48353201150894165 0.4838240146636963 0.48353201150894165 0 0 1 0.4838240146636963 0.48353201150894165 z'
          transform='rotate(39.2155 45.0324 13.1313)'
          fill='#D9D9D9'
          id='ellipse7851'
        ></path>
        <path
          d=' M 54.2365 27.2111 L 56.4811 24.4605 C 56.8726 23.9807 56.8011 23.2745 56.3213 22.883 L 54.4856 21.385 C 54.1052 21.0746 53.5453 21.1313 53.2349 21.5117 C 52.9245 21.8921 52.3645 21.9488 51.9841 21.6384 L 42.8053 14.1482 C 42.425 13.8378 42.3682 13.2778 42.6786 12.8975 C 42.989 12.5171 42.9323 11.9571 42.5519 11.6467 L 39.9622 9.53341 C 39.4825 9.14194 38.7762 9.21348 38.3847 9.69321 L 36.1401 12.4439 L 54.2365 27.2111 Z '
          fill='white'
          id='path9910'
          stroke='#00B8EA'
          strokeWidth='0.823396'
        ></path>

        <g clipPath='url(#clip1_998_23388)' id='g8050'>
          <path
            d='m 0 0 l 23.35700035095215 0 l 0 24.29129981994629 l -23.35700035095215 0 z'
            transform='translate(36.1404 12.4442) rotate(39.2155)'
            fill='#00B8EA'
            id='rect9262'
          ></path>
          <path
            d='m 0 0 l 19.61989974975586 0 l 0 15.882800102233887 l -19.61989974975586 0 z'
            transform='translate(35.8165 15.7969) rotate(39.2155)'
            fill='white'
            id='rect7150'
          ></path>
        </g>

        <path
          d='M 52.92587531594272 98.85823582486506 L -1.7246351877610806 28.904401423333013 C -4.056696755162853 25.003042105696764 -1.9364885644930583 23.19004876594174 -0.8762185644930582 21.890748765941737 L -0.1992061186719134 21.29268903151859 C 0.3084938813280864 20.670499031518624 0.6877328010456978 20.326780321225723 1.1080271395229424 19.85729869194614 C 1.7482429341801478 20.688512068375555 3.3286629277276063 22.220605572358302 3.9087536764196606 22.829044996688708 L 5.330761884854731 24.655157419714783 C 5.573034180057565 24.96548135959955 16.748242731116555 39.36396462519157 16.93734273111655 39.714664625191574 C 17.126442731116548 40.06546462519166 29.950823528932823 55.89735332527294 29.991523528932817 56.293453325272935 C 30.03212352893283 56.68945332527292 33.86561010866451 61.283141346915954 34.10794296109099 61.64553334253304 C 34.320114451436424 61.89885495908575 53.760450606883374 86.3126561737939 53.50905060688338 86.6207561737939 L 57.68462563738433 92.5762252114418 C 57.174325637384314 93.21052521144188 56.9337799993366 97.47341142829463 56.1240799993366 97.56681142829461 C 55.3143799993366 97.66031142829463 53.45578817596962 99.4450117852003 52.92587531594272 98.85823582486506 Z'
          fill='#272425'
          transform='matrix(0.2825920581817627 0 0 0.17944286811459875 7.0234375 45.78294214680591)'
          id='path4800'
        ></path>
      </g>
      <defs id='defs8184'>
        <clipPath id='clip0_998_23388'>
          <rect
            width='27.0942'
            height='55.1226'
            fill='white'
            transform='translate(38.8279 6.19568) rotate(39.2155)'
            id='rect5850'
          ></rect>
        </clipPath>
        <clipPath id='clip1_998_23388'>
          <rect
            width='23.357'
            height='24.2913'
            fill='white'
            transform='translate(36.1404 12.4442) rotate(39.2155)'
            id='rect9100'
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileAppsA;
