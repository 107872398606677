import { useContext } from 'react';
import { ThemeContext } from '../../../context/theme-context';

const VR = ({ setIsUpdateVR }) => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <svg
      style={{ position: 'relative', top: '-10px' }}
      onMouseEnter={() => setIsUpdateVR(true)}
      onMouseLeave={() => setIsUpdateVR(false)}
      xmlns='http://www.w3.org/2000/svg'
      width='62'
      height='57'
      viewBox='0 0 62 57'
      fill='none'
      id='svg553'
      preserveAspectRatio='xMidYMid'
    >
      <path
        d=' M 0.964355 32.5962 C 1.18882 29.9026 5.92127 27.7328 8.25944 26.9846 C 8.25944 28.9019 7.4011 32.4839 7.4011 32.5962 C 7.4011 32.7365 4.33132 34.4199 4.19103 34.4199 C 4.05074 34.4199 0.683775 35.9631 0.964355 32.5962 Z '
        fill='#2F2F2F'
        stroke='white'
        strokeWidth='0.4'
        id='path6708'
      ></path>
      <path
        d=' M 24.9375 25.0385 C 21.0093 24.9262 17.9697 25.3658 16.9409 25.5996 C 16.9409 27.4234 16.969 30.9587 17.0812 30.5098 C 17.2215 29.9486 20.0273 28.9666 20.869 28.8263 C 21.7108 28.686 28.1641 28.5277 28.7253 28.2471 C 29.1742 28.0227 32.6534 27.0025 34.7578 26.7219 L 33.916 25.5996 C 32.5599 25.4593 28.8656 25.1507 24.9375 25.0385 Z '
        fill='#2F2F2F'
        stroke='white'
        strokeWidth='0.4'
        id='path1197'
      ></path>
      <path
        d=' M 32.7913 55.671 C 32.7913 55.671 25.1786 50.9115 24.0058 50.0309 C 22.8329 49.1504 22.8473 49.0729 22.6926 48.1684 V 33.8588 C 22.6926 32.7365 22.9732 31.8947 24.237 31.6033 C 25.5008 31.3118 36.4844 29.4536 36.4844 29.4536 L 38.7589 31.1596 L 44.2957 29.8334 L 42.9868 27.9045 L 45.8405 26.704 L 59.3647 28.9891 L 44.7182 33.7185 C 38.2648 34.4199 33.5765 34.0869 33.0864 35.143 C 31.8151 37.8831 31.4147 49.1814 32.7913 55.671 Z '
        fill={light ? '#2F2F2F' : 'white'}
        id='path3750'
      ></path>
      <path
        d=' M 14.0259 24.6954 C 13.9063 24.6954 8.80396 25.5605 8.80396 25.4414 C 11.1889 16.6031 15.117 15.3405 18.3437 13.7973 C 21.0092 13.2362 24.8345 15.2761 24.5164 15.4808 C 19.6063 14.6391 13.9926 20.9809 14.388 24.6954 C 14.3282 24.8146 14.1456 24.6954 14.0259 24.6954 Z '
        fill='#2F2F2F'
        stroke='white'
        strokeWidth='0.4'
        id='path1473'
      ></path>

      <path
        d=' M 26.4805 51.6756 C 22.565 51.4235 17.4249 51.6158 15.3422 47.543 C 14.5098 46.0774 14.2752 45.1054 14.4155 44.9651 C 12.8723 44.8248 17.068 42.4165 14.1219 41.8553 C 17.2083 38.769 5.20165 45.3626 22.6871 45.3626 C 22.5523 48.8698 25.4984 51.1145 26.4805 51.6756 Z M 22.6926 45.9238 L 14.4155 44.9651 C 14.1763 44.9055 13.8531 37.4727 14.2059 35.6261 C 15.0432 31.0976 17.4954 28.7738 21.682 28.4759 C 26.1677 28.178 31.095 27.3512 34.0855 26.6958 C 34.3247 26.6362 34.5575 26.606 34.6173 26.8443 C 34.6771 27.0826 36.8206 29.3099 36.5814 29.3695 C 33.5909 30.025 24.9373 31.3336 24.3761 31.6142 C 22.8329 32.3857 22.9732 32.1753 22.6926 33.5782 C 22.6022 35.2043 22.6926 39.3134 22.6926 41.1539 C 22.6328 41.3922 22.6926 45.6432 22.6926 45.9238 Z M 45.5599 26.8443 C 45.4403 26.8443 43.5958 27.686 43.4556 27.686 C 43.0369 27.2689 42.7888 26.4446 41.7721 26.5637 C 41.5328 26.5637 41.0801 25.5693 41.0801 25.331 C 41.0801 25.0926 40.5683 25.1604 41.7625 24.8192 C 43.6391 24.478 44.5166 24.7947 45.1745 25.331 C 45.6863 25.8428 46.1602 26.5253 45.9808 26.704 C 45.8612 26.7636 45.8405 26.8443 45.5599 26.8443 Z '
        fill='#00B8EA'
        id='path9561'
      ></path>
      <path
        d=' M 45.1392 29.7904 L 37.8441 31.3335 C 32.9292 25.1899 26.7612 13.657 18.3438 13.7973 C 23.3942 11.8332 27.4626 12.9555 28.7252 13.3764 C 36.506 15.97 40.5408 23.4234 45.1392 29.7904 Z '
        fill='#D9D9D9'
        id='path8609'
      ></path>
      <path
        d=' M 0.968491 32.8766 L 0.968552 33.5781 C 0.921788 35.3083 0.968493 39.0494 0.968542 39.33 C 0.968623 39.7927 4.89661 42.8372 7.28161 43.2581 C 10.4733 44.4166 14.4243 45.0874 16.5407 45.2222 C 17.1901 45.2635 18.1731 44.9802 18.2242 44.5207 C 18.2752 44.0612 18.3645 40.1717 18.3645 38.0674 C 18.2749 37.6093 17.9436 36.9451 16.5407 36.9451 C 11.35 36.9451 7.95494 35.963 6.15922 35.4019 C 4.57473 34.9067 2.99804 34.1107 1.81023 33.4378 C 1.65189 33.3481 1.0895 32.9482 0.968491 32.8766 Z '
        fill='#D9D9D9'
        id='path3891'
      ></path>
      <path
        d=' M 8.804 25.4413 L 6.98022 27.4054 L 7.40109 32.7364 L 10.2069 33.017 L 14.8365 31.8947 L 17.0811 30.2112 L 16.9408 25.5816 L 14.4156 24.5996 L 8.804 25.4413 Z '
        fill={light ? '#969696' : '#2F2F2F'}
        stroke='white'
        strokeWidth='0.4'
        id='path376'
      ></path>
      <path
        d=' M 35.6478 56.9999 C 34.8703 56.9999 34.0329 56.702 33.375 56.1062 C 32.4181 55.3316 31.8798 54.1399 31.8798 53.0078 V 36.9199 C 31.8798 35.3707 32.9564 34.2386 34.5114 34.179 C 42.1071 34.0003 49.7029 32.3319 57.1192 29.1739 C 58.1958 28.6972 59.2723 28.7568 60.1097 29.2931 C 60.8274 29.7698 61.246 30.6039 61.246 31.5573 V 45.1426 C 61.246 46.513 60.4087 47.9431 59.0929 48.6581 C 51.6168 52.8886 43.9014 55.6891 36.1263 56.9403 C 36.0066 56.9999 35.8272 56.9999 35.6478 56.9999 Z M 58.5546 29.7698 C 58.1958 29.7698 57.8369 29.8293 57.4781 30.0081 C 49.9421 33.2257 42.2268 34.894 34.5114 35.0728 C 33.4946 35.0728 32.7769 35.8474 32.7769 36.9199 V 53.0078 C 32.7769 53.9015 33.2554 54.7953 33.9731 55.4507 C 34.5712 55.987 35.3487 56.2253 36.0066 56.1062 C 43.6622 54.8549 51.3177 52.114 58.6742 47.9431 C 59.6312 47.4068 60.3489 46.2747 60.3489 45.2022 V 31.6169 C 60.3489 30.9614 60.1097 30.4252 59.6312 30.0677 C 59.2723 29.8889 58.9135 29.7698 58.5546 29.7698 Z M 35.6478 54.3782 C 35.5282 54.3782 35.4085 54.3186 35.3487 54.259 C 35.1693 54.0803 35.1693 53.7824 35.3487 53.6036 L 56.8202 32.2127 C 56.9996 32.034 57.2986 32.034 57.4781 32.2127 C 57.6575 32.3915 57.6575 32.6894 57.4781 32.8682 L 36.0066 54.259 C 35.8272 54.3782 35.7674 54.3782 35.6478 54.3782 Z M 47.8488 49.7902 C 47.7292 49.7902 47.6096 49.7306 47.5498 49.671 C 47.3704 49.4923 47.3704 49.1943 47.5498 49.0156 L 56.2819 40.3162 C 56.4613 40.1375 56.7604 40.1375 56.9398 40.3162 C 57.1192 40.495 57.1192 40.7929 56.9398 40.9717 L 48.2077 49.671 C 48.0881 49.7902 47.9684 49.7902 47.8488 49.7902 Z M 36.844 45.1426 C 36.7243 45.1426 36.6047 45.083 36.5449 45.0234 C 36.3655 44.8447 36.3655 44.5467 36.5449 44.368 C 37.4134 43.5028 43.9802 36.1046 44.7388 36.8603 C 44.9182 37.0391 44.9182 37.337 44.7388 37.5158 L 37.2028 45.0234 C 37.0234 45.083 36.9636 45.1426 36.844 45.1426 Z '
        fill='#B9BCBC'
        id='path8997'
      ></path>
    </svg>
  );
};

export default VR;
