
import styles from '../Astafiev.module.scss';

export const AudioProgressBar = ({currentProgress, duration, buffered, audioRef, setCurrentProgress}) => {

    const progressBarWidth = isNaN(currentProgress / duration) ? 0 : currentProgress / duration
    const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration

    const progressStyles = {
        "--progress-width": progressBarWidth,
        "--buffered-width": bufferedWidth
    }

    function formatDurationDisplay(duration) {
        const min = Math.floor(duration / 60);
        const sec = Math.floor(duration - min * 60);
        const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":"); 
        return formatted;
    }

    const durationTime = formatDurationDisplay(duration)
    const currentTime = formatDurationDisplay(currentProgress)

    const setCurrentProgressHandler = (e) => {
        audioRef.current.currentTime = e.target.value
        setCurrentProgress(e.target.value)
    }





    return <div className={styles.audioPlayerProgress}>
        <div className={styles.timing}>
            <div>{currentTime}</div>
            <div>{durationTime}</div>
        </div>
        <input
            type="range"
            name="progress"
            style={progressStyles}
            min={0}
            max={duration}
            value={currentProgress}
            onChange={e =>  setCurrentProgressHandler(e)}
        />
    </div>
}