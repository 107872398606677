import React from 'react';
import PageTemplate from '../../PageTemplate/PageTemplate';
import Container from '../../elements/Container/Container';
import styles from './Contacts.module.scss';
import { ThemeContext } from '../../../context/theme-context';
import { useContext } from 'react';
import docImg from '../../../assets/image/doc.png';
import iteo_info from '../../../assets/docs/iteo_info.docx'
import tech_stack from '../../../assets/docs/tech_stack.docx'
import phoneIcon from '../../../assets/image/icons/phoneIcon.png'
import mapIcon from '../../../assets/image/icons/mapIcon.png'
import mailIcon from '../../../assets/image/icons/mailIcon.png'
import { Helmet } from 'react-helmet';

const Contacts = () => {
  const { theme, toggle, light } = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>Итео - Контакты</title>
        <meta name='description' content='Как с нами можно связаться.' />
      </Helmet>
      <PageTemplate>
        <Container width={90}>
          <section className={styles.container}>
            <div className={styles.map}>
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                <iframe
                  src='https://yandex.ru/map-widget/v1/?azimuth=0.02650381736443208&indoorLevel=1&ll=92.855705%2C56.014403&mode=search&sctx=ZAAAAAgBEAAaKAoSCQ9fJoqQNldAERnG3SBaAUxAEhIJ8bkT7L%2FO5j8RwOyePCzUzD8iBgABAgMEBSgKOABArM0GSAFiFm1pZGRsZV9yd3I9b2ZmOkdlb0FkZHJiF2V4cF9pZD1hbGxfZm9ybXVsYXNfYmFuYihyZWxldl91c2VfZ2Vvc2VhcmNoX2dlb2FkZHJfZGlyZWN0aW9ucz0xYjNyZWFycj1zY2hlbWVfTG9jYWwvR2VvL1NwZWNpYWxSdWJyaWNXaW5kb3cvQW55RG9jPTFqAnJ1nQHNzEw9oAEAqAEAvQEJ9W5zwgEGw8%2Fc9q0G6gEA8gEA%2BAEAggII0LjRgtC10L6KAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=92.855705%2C56.014403&sspn=0.008677%2C0.002742&text=%D0%B8%D1%82%D0%B5%D0%BE&z=18.36'
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
            <div className={styles.contacts}>
              <div className={styles.wrapper}>
                <h2>Общество с ограниченной<br/>ответственностью «ИТЕО»<br/>Интерактивные решения (ООО «ИТЕО»)</h2>
                <p className={styles.ogrn}>ОГРН 1142468052979<br/>ИНН 2466276329 КПП 246601001</p>
                <div className={styles.contactsGrid}>
                  <div>
                    <div className={styles.contact}>
                      <h3>Адрес</h3>
                      <p className={styles.withIcons}>
                        <img src={mapIcon} alt="map-marker" />
                        <div>
                          <span>Красноярск, ул. Красной армии</span>
                          <span>дом 10 кор. 3, офис 503</span>
                        </div>
                      </p>
                    </div>
                    <div className={styles.contact}>
                      <h3>Связаться с нами</h3>
                      <p className={styles.withIcons}>
                        <img src={phoneIcon} alt="map-marker" />
                        <div>
                          <a
                            style={{ textDecoration: 'underline' }}
                            href='tel:+79138396909'
                          >
                            +7 913 839 6909
                          </a>
                        </div>
                      </p>
                      <p className={styles.withIcons}>
                        <img src={mailIcon} alt="map-marker" />
                        <div>
                          <a href='mailto:info@iteo.pro'>info@iteo.pro</a>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className={styles.contact}>
                    <h3>Приложение</h3>
                    <div className={styles.docs}>
                      <a href={iteo_info} download='Виды деятельности в области информационных технологий.docx'>
                        <img src={docImg} alt='doc'/>
                      </a>
                      <a href={iteo_info} download='Виды деятельности в области информационных технологий.docx'>Виды деятельности в области информационных технологий</a>
                    </div>
                    <div className={styles.docs}>
                      <a href={tech_stack} download='Стек используемых технологий и языки программирования.docx'>
                        <img src={docImg} alt='doc'/>
                      </a>
                      <a href={tech_stack} download='Стек используемых технологий и языки программирования.docx'>Стек используемых технологий и языки программирования</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Contacts;
